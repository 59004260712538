import { KTSVG } from "../../../_metronic/helpers"
import { getIndicadores } from "../../../services/utilService";
import { FiltroUsuario } from "../Filtros/FiltroUsuario";
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";

const HeaderListaUsuario = ({filtrosSeleccionados, getUsuarios, onExporta }: any) => {
  const navigate = useNavigate();
  const [showFiltros, setShowFiltros] = useState<boolean>(false)
  const [indicadores, setIndicadores] = useState<any>(null)


  useEffect(() => {
    const fetchData = async () => {
      const data = await getIndicadores(new Date().getFullYear())

      setIndicadores(data)
    }
    fetchData()
  }, [])



  const onBuscarUsuario = (inputValue: string) => {
    if (inputValue.length > 3 || inputValue === '') {
      getUsuarios(inputValue, 'nombreOMail')
    }
  }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Buscar usuario'
            onChange={(e) => onBuscarUsuario(e.target.value)}
          />
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button
            type='button'
            className='btn btn-light-primary me-3'
            onClick={() => setShowFiltros(!showFiltros)}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
            Filtros
          </button>

          <button type='button' onClick={()=> onExporta()} className='btn btn-light-primary me-3'>
            <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
            Exportar
          </button>
          <button type='button' className='btn btn-primary' onClick={() => navigate('/administracion/crearUsuario')}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Crear Usuario
          </button>
        </div>
      </div>
      {showFiltros ? <FiltroUsuario filtrosSeleccionados={filtrosSeleccionados} onBuscar={getUsuarios} indicadores={indicadores}></FiltroUsuario> : null}
    </div>
  )
}

export { HeaderListaUsuario }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify"
import { getActualizacionFiltrosReportes } from '../../../services/IndicadorService'
import { setListadoFiltros, setTabActivo, setVerSoloCompromisos, setFiltrosSeleccionados, indicadoresRegionalesAction, isLoading } from '../../../redux/AppSlice'
import { getIndicadoresRegionales } from '../../../services/IndicadorService';
import { DefinirTabActivo } from '../../../services/utilService';
import { setRegionDefecto } from '../../../services/helperService';
import { codigoPerfiles } from '../../interfaces/enumeradores';

export function FiltroRegional() {
    const dispatch = useDispatch()
    const filtros = useSelector((store: any) => store.appReducer.filtrosReportes)
    const seleccionfiltros = useSelector((store: any) => store.appReducer.filtrosReporteSeleccionado)
    const usuario = useSelector((store: any) => store.appReducer.usuario)
    const tabActivo = useSelector((store: any) => store.appReducer.tabsActivos.regional)
    const gestorVeSoloSusCompromisos = useSelector((store: any) => store.appReducer.gestorVeSoloSusCompromisos)
    const mesSeleccionado = seleccionfiltros.mes

    const onChangeFiltro = async (nombreFiltro: string, e: any) => {
        let estabaMes: boolean = true

        //actualizacion listado filtros
        if (nombreFiltro == 'agno') {
            const data = await getActualizacionFiltrosReportes(parseInt(e.target.value), 0)
            dispatch(setListadoFiltros({ ...filtros, periodos: data.actualizacion }))

            //si el mes seleccionado no existe en el año filtrado, se actualiza al ultimo mes disponible del año seleccionado
            if (!data?.actualizacion?.some((x: any) => x.value === mesSeleccionado)) {
                dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, mes: data.actualizacion[0].value, [nombreFiltro]: parseInt(e.target.value) }))
            
                estabaMes = false
            }
        }
        else if (nombreFiltro == 'mes') {
            const data = await getActualizacionFiltrosReportes(0, parseInt(e.target.value))

            dispatch(setListadoFiltros({ ...filtros, agnos: data.actualizacion }))
        }

        //actualizacion filtros seleccionados
        if (estabaMes) {
            dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, [nombreFiltro]: parseInt(e.target.value) }))
        }
    }

    const actualizarGrillaIndicadores = async () => {
        try {
            dispatch(isLoading(true))

            const filtros = {
                ...seleccionfiltros,
                GestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
            }

            const data = await getIndicadoresRegionales(filtros)
            const tab: number = DefinirTabActivo(tabActivo, data)

            dispatch(indicadoresRegionalesAction(data))
            dispatch(setTabActivo({ regional: tab }))
        }
        catch {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-2'>
                    <label className='form-label'>Año:</label>
                    <div>
                        {filtros?.agnos ?
                            <select
                                className='form-select'
                                onChange={e => onChangeFiltro('agno', e)}
                                defaultValue={seleccionfiltros?.agno.toString()}
                            >
                                {filtros?.agnos.map((item: any, index: number) => (
                                    <option key={item.value} value={item.glosa}>{item.glosa}</option>
                                ))}
                            </select> : null}
                    </div>
                </div>
                <div className='col-md-2'>
                    <label className='form-label'>Período:</label>

                    <div>
                        {filtros?.periodos ? <select
                            className='form-select'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            onChange={e => onChangeFiltro('mes', e)}
                            defaultValue={seleccionfiltros?.mes.toString()}
                        >
                            {filtros?.periodos.map((item: any, index: number) => (
                                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
                            ))}
                        </select> : null}
                    </div>
                </div>
                <div className='col-md-3'>
                    <label className='form-label'>Región:</label>

                    <div>
                        {usuario && filtros ? <select
                            className='form-select'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            onChange={e => onChangeFiltro('idRegion', e)}
                            defaultValue={seleccionfiltros.idRegion?.toString() || setRegionDefecto(usuario, filtros)}
                        >
                            {filtros?.regiones.map((item: any, index: number) => (
                                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
                            ))}
                        </select> : null}
                    </div>
                </div>
                <div className='col-md-2'>
                    <label className='form-label'>Area:</label>
                    <div>
                        <select
                            className='form-select'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            onChange={e => onChangeFiltro('idArea', e)}
                            defaultValue={seleccionfiltros.idArea?.toString() ?? ''}
                        >
                            <option value=''>Todas</option>
                            {filtros?.areas.map((item: any, index: number) => (
                                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='col-md-2'>
                    <label className='form-label'>Compromiso:</label>
                    <div>
                        <select
                            className='form-select'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            defaultValue={seleccionfiltros.idCategoria?.toString() ?? ''}
                            onChange={e => onChangeFiltro('idCategoria', e)}
                        >
                            <option value=''>Todos</option>
                            {filtros?.compromisos.map((item: any, index: number) => (
                                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {usuario?.rol.codigo == codigoPerfiles.GESTOR ?
                    <div className='col-md-2 mt-2'>
                        <label className='form-label'> Mis compromisos:</label>
                        <div style={{ textAlign: 'center' }}>
                            <input
                                className='form-check-input mt-1'
                                type='checkbox'
                                value=''
                                checked={gestorVeSoloSusCompromisos}
                                style={{
                                    height: '30px',
                                    width: '30px'
                                }}
                                name='notifications'
                                onChange={() =>
                                    dispatch(setVerSoloCompromisos(!gestorVeSoloSusCompromisos))
                                }
                            />
                        </div>
                    </div> : null}
                <div className='col-md-1'>
                    <br></br>
                    <div className='d-flex justify-content-center pt-3'>
                        <button type='button' onClick={() => actualizarGrillaIndicadores()} className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
                            Aplicar
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
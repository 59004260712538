/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG, ID } from '../../../_metronic/helpers'
import { ModalEditarUsuario } from '../Modales/ModalEditarUsuario'
import { MenuComponent } from '../../../_metronic/assets/ts/components'

type Props = {
  id: number
}

const AccionUsuarioCell: FC<Props> = ({ id }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const onClose = () => {
    setShowModal(false)
  }

  const openEditModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <ModalEditarUsuario
        show={showModal}
        handleClose={onClose}
        idUsuario={id}
      ></ModalEditarUsuario>
      <a href='#' style={{backgroundColor:'#9B98FD'}} title='Editar' onClick={openEditModal} className='btn btn-icon btn-sm border-0'>
        <KTSVG
          path='/media/icons/duotune/arrows/arr064.svg'
          className='svg-icon-2 svg-icon-primary'
        />
      </a>
      {/* <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Acciones
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a> */}
      {/* <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
      </div> */}
    </>
  )
}

export { AccionUsuarioCell }

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { AuthPage } from '../modules/auth'
import { getFiltrosReportes } from '../../services/IndicadorService'
import { getInfoUsuario } from '../../services/usuarioService'
import { App } from '../App'
import { setUsuarioSesion, setListadoFiltros } from '../../redux/AppSlice'
//azure
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Loader from '../components/Helper/Loader'
import { useIsAuthenticated } from "@azure/msal-react";

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const dispatch = useDispatch()
  const usuario = useSelector((store: any) => store.appReducer.usuario)
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        Promise.all([getFiltrosReportes(), getInfoUsuario()]).then(values => {
          dispatch(setListadoFiltros(values[0]))
          dispatch(setUsuarioSesion(values[1]))
        }).catch(reason => {
          console.log(reason)
        })
      }
    }
    fetchData()
  }, [isAuthenticated])

  return (
    <>
      {isAuthenticated && !usuario ? <Loader></Loader> : null}

      <AuthenticatedTemplate>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='error/*' element={<ErrorsPage />} />

              {usuario ?
                <Route path='/*' element={<PrivateRoutes />} />
                : null}
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <BrowserRouter basename={PUBLIC_URL}>
          <Routes>
            <Route element={<App />}>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </>
  )
}

export { AppRoutes }

import CustomSelect from '../Helper/CustomSelect';

export function FiltroPeriodo({ onBuscar, filtrosSeleccionados }: any) {
    const estados = [{ label: 'Abierto', value: 1 }, { label: 'Cerrado', value: 2 }]

    const onChange = (value: any, input: string) => {

        const filtro = {
            ...filtrosSeleccionados,
            [input]: value
        }

        onBuscar(filtro)
    }

    return (
        <>
            <div className='row pt-5' style={{ width: '100%' }}>
                <div className='col-md-4'>
                    <label className='form-label'>
                        Período:</label>
                    <div>
                        <input onChange={e => onChange(e.target.value, 'Periodo')} style={{ height: '38px' }} className='form-control' min='2020-01' type='month'></input>
                    </div>
                </div>
                <div className='col-md-4'>
                    <label className='form-label'>
                        Estado:</label>
                    <div>
                        <CustomSelect
                            isClearable={true}
                            value={filtrosSeleccionados.Estado}
                            className='fw-semibold'
                            noOptionsMessage={() => "No se encontraron resultados."}
                            placeholder={'Seleccione estado...'}
                            onChange={(e: any) => onChange(e?.value, 'Estado')}
                            options={estados}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
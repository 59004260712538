import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const App = () => {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>        
        <I18nProvider> {/* lenguaje */}
          <LayoutProvider>
              <Outlet />
              <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
      <ToastContainer />

    </>
  )
}

export { App }

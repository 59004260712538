// @ts-ignore
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { ValidarDecimalGrilla } from '../../../services/helperService';
import { UpdateMetasParciales } from '../../../services/AdminIndicador';

type Props = {
    show: boolean
    handleClose: () => void,
    data: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalMetasParcial = ({ show, handleClose, data }: Props) => {
    const dispatch = useDispatch()

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            row.fueActualizado = true
        }
    });

    const colums: any = [
        {
            dataField: 'fechaControl',
            text: 'Fecha de Control',
            sort: false,
            editable: false,
        },
        {
            dataField: 'metaParcial',
            text: 'Meta',
            sort: false,
            validator: (newValue: any, row: any, column: any) => {
                return ValidarDecimalGrilla(newValue)
            }
        },
    ]

    const onActualizarMetasParciales = async () => {
        try {
            let payload = data.data.filter((x: any) => x.fueActualizado)

            if (payload?.length > 0) {
                payload = payload.map(function (item: any) {
                    return {
                        ...item,
                        metaParcial: parseFloat(item.metaParcial)
                    }
                });

                dispatch(isLoading(true))
                const result = await UpdateMetasParciales(payload)
                handleClose()
                toast.success('Metas actualizadas correctamente.')
            }
        }
        catch {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header p-5'>
                <h3 className='text-gray-600 text-hover-primary fs-2 fw-semibold'>Metas parciales de {data?.centroResponsable}</h3>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row g-5 g-xxl-8'>
                    <div className='table-responsive mt-1'>
                        <BootstrapTable
                            cellEdit={cellEdit}
                            keyField={'idMetaParcial'}
                            data={data?.data}
                            columns={colums}
                            rowClasses="custom-row-class"
                            classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
                        />
                    </div>
                </div>
                <div className='text-center pt-2'>
                    <button
                        type='button'
                        onClick={() => handleClose()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => onActualizarMetasParciales()}
                    >
                        <span className='indicator-label'>Guardar</span>
                    </button>
                </div>
            </div>

        </Modal >,
        modalsRoot
    )
}

export { ModalMetasParcial }

import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const getPeriodos = async (data) => {
    return axios.post(`${urlApi}/api/AdminPeriodo/Periodos`, data)
        .then((res) => { return res.data })
}

export const UpdatePeriodo = async (data) => {
    return axios.post(`${urlApi}/api/AdminPeriodo/Update`, data)
        .then((res) => { return res.data })
}

export const getLogPeriodo = async (idPeriodo) => {
    return axios.get(`${urlApi}/api/AdminPeriodo/Log/${idPeriodo}`)
        .then((res) => { return res.data })
}
import { KTCardBody } from '../../../_metronic/helpers'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import dayjs from "dayjs"
import { KTSVG } from '../../../_metronic/helpers';
import { useState } from 'react';
import { ModalEditarPeriodo } from '../Modales/ModalUpdatePeriodo';
import { ModalLogPeriodo } from '../Modales/ModalLogPeriodo';

const TablaPeriodos = ({ data, updateData }: any) => {
  const [showModal, setShowModal] = useState<any>(null)
  const [showModalLog, setShowModalLog] = useState<any>(false)
  const [periodoEditar, setPeriodoEditar] = useState<any>(null)

  const colums: any = [
    {
      dataField: 'periodoProceso',
      text: 'Período',
      sort: false,
      align: 'center',
      formatter: (cell: any, row: any) => (dayjs(cell).format('MM/YYYY'))
    },
    {
      dataField: 'estado',
      text: 'Estado',
      align: 'center',
      sort: false,
      formatter: (cell: any, row: any) => (
        <div className={`badge badge-light-${cell === 'Abierto' ? 'success' : 'danger'}   fw-bolder`}>{cell}</div>
      )
    },
    {
      text: 'Acciones',
      dataField: '',
      width: '25%',
      sort: false,
      align: 'center',
      formatter: (cell: any, row: any) => (
        <>
          <a href='#' style={{ backgroundColor: '#9B98FD' }} title='Editar' onClick={() => onSelectPeriodo(row, 'editar')} className='btn btn-icon btn-sm border-0'>
            <KTSVG
              path='/media/icons/duotune/arrows/arr064.svg'
              className='svg-icon-2 svg-icon-primary'
            />
          </a>
          <div className='symbol symbol-40px me-2'>
            <a
              title='Ver log de cambios'
              onClick={() => onSelectPeriodo(row, 'log')}
              className='symbol-label'
              style={{ backgroundColor: '#9B98FD', cursor: 'pointer', marginLeft: '10px' }}
            >
              <KTSVG
                setSvgIcon={false}
                path='/media/icons/duotune/general/gen063.svg'
                className='svg-icon-3'
              />
            </a>
          </div>
        </>
      )
    },
  ]

  const onSelectPeriodo = (row: any, origen: string) => {
    setPeriodoEditar(row)

    if (origen === 'editar')
      setShowModal(true)
    else
      setShowModalLog(true)
  }

  const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} filas.
    </span>
  );

  return (
    <KTCardBody className='py-4'>
      <ModalEditarPeriodo
        show={showModal}
        handleClose={() => setShowModal(false)}
        periodoSeleccionado={periodoEditar}
        updateData={updateData}
      ></ModalEditarPeriodo>
      <ModalLogPeriodo
        show={showModalLog}
        handleClose={() => setShowModalLog(false)}
        idPeriodo={periodoEditar?.id}
        periodo={periodoEditar?.periodoProceso}
      ></ModalLogPeriodo>

      {data ?
        <div className='table-responsive'>
          <BootstrapTable
            pagination={paginationFactory(
              {
                withFirstAndLast: false,
                page: 1,
                sizePerPage: 12,
                prePageText: '<',
                nextPageText: '>',
                showTotal: true,
                hideSizePerPage: true,
                paginationTotalRenderer: customTotal,
              })}
            keyField={'id'}
            data={data}
            columns={colums}
            rowClasses="fw-semibold text-muted"
            headerClasses="fw-semibold text-muted centerTitle"
            classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
          />
        </div> : null}
    </KTCardBody>
  )
}

export { TablaPeriodos }

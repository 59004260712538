import { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { getDetalleUsuario, UpdateUsuario, ValidarAsignacionIndicador } from '../../../services/AdminUsuarioService'
import { getIndicadoresCentroResponsable } from '../../../services/utilService'
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup'
import { perfilesId } from '../../interfaces/enumeradores'
import { useFormik } from 'formik'
import { EditarUsuarioModel, editarUsuarioInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import CustomSelect from '../Helper/CustomSelect'
import clsx from 'clsx'

type Props = {
    show: boolean
    handleClose: () => void,
    idUsuario: number
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalEditarUsuario = ({ show, handleClose, idUsuario }: Props) => {
    const dispatch = useDispatch()
    const [indicadores, setIndicadores] = useState<any>(null)
    const camposDropdown = useSelector((store: any) => store.appReducer.camposCreaEditaUsuario)

    const getIndicadoresPorCentro = async (idCentro: number) => {
        const indicadores = await getIndicadoresCentroResponsable(idCentro)
        setIndicadores(indicadores)
    }

    const validate = async (values: EditarUsuarioModel) => {
        console.log(values)
        const errors: any = {}

        if (values.nombre === '') {
            errors.nombre = 'Nombre es requerido'
        }

        if (values.perfil === '') {
            errors.perfil = 'Perfil es requerido'
        }

        if (!values.centroResponsable && values.perfil != perfilesId.ADMINISTRADOR.toString()) {
            errors.centroResponsable = 'Centro Responsable es requerido'
        }

        if (values.perfil == perfilesId.GESTOR.toString() && values.centroResponsable) {
            if (camposDropdown.centrosResponsables.some((x: any) => x.value == values.centroResponsable && !x.tieneGestor)) {
                errors.centroResponsable = 'Centro responsable no posee gestor.'
            }
            else {
                const resultado = await ValidarAsignacionIndicador({
                    IdCentroResponsable: values.centroResponsable,
                    IdIndicador: values.indicadores,
                    IdUsuario: values.idUsuario,
                    IdRol: parseInt(values.perfil)
                })
                if (resultado != '') {
                    errors.indicadores = resultado
                }
            }
        }

        if (values.perfil == perfilesId.SUPERVISOR.toString() && values.centroResponsable !== '') {
            if (camposDropdown.centrosResponsables.some((x: any) => x.value == values.centroResponsable && !x.tieneSupervisor)) {
                errors.centroResponsable = 'Centro responsable no posee supervisor.'
            }
            else {
                const resultado = await ValidarAsignacionIndicador({
                    IdCentroResponsable: values.centroResponsable,
                    IdIndicador: values.indicadores,
                    IdUsuario: values.idUsuario,
                    IdRol: parseInt(values.perfil)
                })
                if (resultado != '') {
                    errors.centroResponsable = resultado
                }
            }
        }

        return errors;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (show) {
                    dispatch(isLoading(true))
                    formik.resetForm()

                    const data = await getDetalleUsuario(idUsuario)

                    formik.setValues({
                        idUsuario: data.idUsuario,
                        activo: data.estado ? '1' : '0',
                        centroResponsable: data.idCentroResponsable,
                        email: data.email,
                        indicadores: data.compromisos?.length > 0 ? data.compromisos?.map((x: any) => { return x.idDefIndicador }) : null,
                        nombre: data.nombre,
                        perfil: data.rol.idRol
                    }, false)

                    if (data.idCentroResponsable) {
                        getIndicadoresPorCentro(data.idCentroResponsable)
                    }
                }
            }
            catch (error) {
                console.log(error)
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        }
        fetchData()
    }, [idUsuario, show])


    const formik = useFormik<EditarUsuarioModel>({
        initialValues,
        // validationSchema: profileDetailsSchema,
        validate,
        onSubmit: async (values) => {
            try {
                dispatch(isLoading(true))
                const data = {
                    IdUsuario: formik.values.idUsuario,
                    CentroResponsable: formik.values.centroResponsable,
                    Perfil: formik.values.perfil,
                    Nombre: formik.values.nombre,
                    Indicadores: formik.values.indicadores,
                    Activo: formik.values.activo === '1' ? true : false
                }

                const resultado: boolean = await UpdateUsuario(data)

                if (resultado) {
                    onCancelar()
                    formik.resetForm()
                    toast.success('Usuario actualizado correctamente.')
                }
                else {
                    toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                }
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        },
    })

    const onChangeCustomSelect = async (value: any, isMulti: boolean, inputName: string) => {
        if (inputName == 'centroResponsable') {
            await getIndicadoresPorCentro(value.value)
        }

        if (inputName == 'perfil' && value.value != perfilesId.GESTOR) {
            formik.setFieldValue('indicadores', null)
        }

        if (inputName == 'centroResponsable') {
            formik.setFieldValue('indicadores', null)
        }

        if (inputName == 'perfil' && value.value == perfilesId.ADMINISTRADOR) {
            formik.setFieldValue('centroResponsable', null)
        }

        if (isMulti) {
            const values = value.map((item: any) => item.value)

            formik.setFieldValue(inputName, values)
        }
        else {
            formik.setFieldValue(inputName, value.value)
        }
    }

    const onCancelar = () => {
        handleClose()
        formik.resetForm()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header'>
                <a href='#' className='text-gray-700 text-hover-primary fs-2 me-1'>
                    Mail: {formik.values.email}
                </a>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>

                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                    {/* begin::Scroll */}
                    <div
                        className='d-flex flex-column scroll-y me-n7 pe-7'
                        id='kt_modal_add_user_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                        data-kt-scroll-offset='300px'
                    >
                        <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Nombre</label>
                            <input
                                placeholder='Nombre'
                                {...formik.getFieldProps('nombre')}
                                type='text'
                                name='nombre'
                                maxLength={500}
                                className={clsx(
                                    'form-control mb-3 mb-lg-0',
                                    { 'is-invalid': formik.touched.nombre && formik.errors.nombre },
                                    {
                                        'is-valid': formik.touched.nombre && !formik.errors.nombre,
                                    }
                                )}
                                autoComplete='off'
                            />
                            {formik.touched.nombre && formik.errors.nombre && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.nombre}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Perfil</label>
                            <CustomSelect
                                className='fw-bold'
                                value={formik.values.perfil}
                                noOptionsMessage={() => "No se encontraron resultados."}
                                placeholder={'Seleccione perfil...'}
                                onChange={(value: any) => onChangeCustomSelect(value, false, 'perfil')}
                                options={camposDropdown?.perfiles}
                            />
                            {formik.touched.perfil && formik.errors.perfil && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.perfil}</div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Centro responsable</label>
                            <CustomSelect
                                onChange={(value: any) => onChangeCustomSelect(value, false, 'centroResponsable')}
                                options={camposDropdown?.centrosResponsables}
                                value={formik.values.centroResponsable}
                                className='fw-bold'
                                placeholder={'Seleccione centro responsable...'}
                                isMulti={false}
                                disabled={formik.values.perfil == perfilesId.ADMINISTRADOR.toString()}
                            />
                            {formik.touched.centroResponsable && formik.errors.centroResponsable && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.centroResponsable}</div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-7'>
                            <label className='required fw-bold fs-6 mb-2'>Indicadores</label>
                            <CustomSelect
                                className='fw-bold'
                                isMulti={true}
                                noOptionsMessage={() => "No se encontraron resultados."}
                                placeholder={'Seleccione Indicadores...'}
                                onChange={(value: any) => onChangeCustomSelect(value, true, 'indicadores')}
                                disabled={formik.values.perfil != perfilesId.GESTOR.toString()}
                                options={indicadores}
                                value={formik.values.indicadores}
                            />
                            {formik.errors.indicadores && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.indicadores}</div>
                                </div>
                            )}
                        </div>
                        <div className='mb-7'>
                            <label className='required fw-bold fs-6 mb-5'>Estado</label>
                            <div className='d-flex fv-row'>
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input me-3'
                                        {...formik.getFieldProps('activo')}
                                        name='activo'
                                        type='radio'
                                        value={'1'}
                                        id='kt_modal_update_role_option_0'
                                        checked={formik.values.activo === '1'}
                                    />
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                                        <div className='fw-bolder text-gray-800'>Habilitado</div>
                                        <div className='text-gray-600'>
                                            Se le habilita al usuario el acceso al sistema.
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='separator separator-dashed my-5'></div>
                            <div className='d-flex fv-row'>
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input me-3'
                                        {...formik.getFieldProps('activo')}
                                        name='activo'
                                        type='radio'
                                        value={'0'}
                                        id='kt_modal_update_role_option_1'
                                        checked={formik.values.activo === '0'}
                                    />
                                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                                        <div className='fw-bolder text-gray-800'>Inhabilitado</div>
                                        <div className='text-gray-600'>
                                            Se le restringe al usuario el acceso al sistema.
                                        </div>
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='text-center pt-15'>
                        <button
                            type='reset'
                            onClick={() => onCancelar()}
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                            disabled={formik.isSubmitting}
                        >
                            Cancelar
                        </button>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                        >
                            <span className='indicator-label'>Guardar</span>
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ModalEditarUsuario }

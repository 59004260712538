/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import { getActualizacionFiltrosReportes } from '../../../services/IndicadorService'
import { setListadoFiltros, setVerSoloCompromisos, setFiltrosSeleccionados, setTabActivo, indicadoresNacionalesAction, isLoading } from '../../../redux/AppSlice'
import { DefinirTabActivo } from '../../../services/utilService';
import { getIndicadoresNacionales } from '../../../services/IndicadorService';
import { codigoPerfiles } from '../../interfaces/enumeradores';

export function FiltroNacional() {
  const dispatch = useDispatch()
  const usuario = useSelector((store: any) => store.appReducer.usuario)
  const filtros = useSelector((store: any) => store.appReducer.filtrosReportes)
  const seleccionfiltros = useSelector((store: any) => store.appReducer.filtrosReporteSeleccionado)
  const tabActivo = useSelector((store: any) => store.appReducer.tabsActivos.nacional)
  const gestorVeSoloSusCompromisos = useSelector((store: any) => store.appReducer.gestorVeSoloSusCompromisos)

  const onChangeFiltro = async (nombreFiltro: string, e: any) => {
    let estabaMes: boolean = true

    //actualizacion listado filtros
    if (nombreFiltro == 'agno') {
      const data = await getActualizacionFiltrosReportes(parseInt(e.target.value), 0)
      const mesSeleccionado = seleccionfiltros.mes

      //si el mes seleccionado no existe en el año filtrado, se actualiza al ultimo mes disponible del año seleccionado
      if (!data?.actualizacion?.some((x: any) => x.value === mesSeleccionado)) {
        dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, mes: data.actualizacion[0].value, [nombreFiltro]: parseInt(e.target.value) }))

        estabaMes = false
      }

      dispatch(setListadoFiltros({ ...filtros, periodos: data.actualizacion }))
    }
    else if (nombreFiltro == 'mes') {
      const data = await getActualizacionFiltrosReportes(0, parseInt(e.target.value))

      dispatch(setListadoFiltros({ ...filtros, agnos: data.actualizacion }))
    }

    //actualizacion filtros seleccionados
    if (estabaMes) {
      dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, [nombreFiltro]: parseInt(e.target.value) }))
    }
  }

  const actualizarGrillaIndicadores = async () => {
    try {
      dispatch(isLoading(true))

      const filtros = {
        ...seleccionfiltros,
        GestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
      }

      const data = await getIndicadoresNacionales(filtros)
      const tab: number = DefinirTabActivo(tabActivo, data)

      dispatch(setTabActivo({ nacional: tab }))
      dispatch(indicadoresNacionalesAction(data))
    }
    catch {
      toast.error('Ha ocurrido un error, inténtelo nuevamente.')
    }
    finally {
      dispatch(isLoading(false))
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-2'>
          <label className='form-label'>Año:</label>
          <div>
            {filtros?.agnos ?
              <select
                className='form-select'
                onChange={e => onChangeFiltro('agno', e)}
                defaultValue={seleccionfiltros?.agno.toString()}
              >
                {filtros?.agnos.map((item: any, index: number) => (
                  <option key={item.value} value={item.glosa}>{item.glosa}</option>
                ))}
              </select> : null}
          </div>
        </div>
        <div className='col-md-2'>
          <label className='form-label'>Período:</label>

          <div>
            {filtros?.periodos ? <select
              className='form-select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={e => onChangeFiltro('mes', e)}
              defaultValue={seleccionfiltros?.mes.toString()}
            >
              {filtros?.periodos.map((item: any, index: number) => (
                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
              ))}
            </select> : null}
          </div>
        </div>
        <div className={`${usuario?.rol.codigo == codigoPerfiles.GESTOR ? 'col-md-4' : 'col-md-3'} `}>
          <label className='form-label'>Centro Responsable:</label>

          <div>
            {usuario && seleccionfiltros ? <select
              className='form-select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={e => onChangeFiltro('idCentroNacional', e)}
              defaultValue={seleccionfiltros?.idCentroNacional}//{setCentroNacionalDefecto(usuario, filtros?.centrosNacionales)}
            >
              <option value=''>Todos</option>
              {filtros?.centrosNacionales.map((item: any, index: number) => (
                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
              ))}
            </select> : null}
          </div>
        </div>
        <div className='col-md-3'>
          <label className='form-label'>Compromiso:</label>
          <div>
            <select
              className='form-select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              defaultValue={seleccionfiltros.idCategoria?.toString() ?? ''}
              onChange={e => onChangeFiltro('idCategoria', e)}
            >
              <option value=''>Todos</option>
              {filtros?.compromisos.filter((x: any) => x.esNacional)?.map((item: any, index: number) => (
                <option key={item.value} value={item.value.toString()}>{item.glosa}</option>
              ))}
            </select>
          </div>
        </div>
        {usuario?.rol.codigo == codigoPerfiles.GESTOR ?
          <div className='col-md-2 mt-2'>
            <label className='form-label'>Mis compromisos:</label>
            <div style={{ textAlign: 'center' }}>
              <input
                className='form-check-input mt-1'
                type='checkbox'
                value=''
                checked={gestorVeSoloSusCompromisos}
                style={{
                  height: '30px',
                  width: '30px'
                }}
                name='notifications'
                onChange={() =>
                  dispatch(setVerSoloCompromisos(!gestorVeSoloSusCompromisos))
                }
              />
            </div>
          </div> : null}

        <div className='col-md-2'>
          <br></br>
          <div className='d-flex justify-content-center pt-3'>
            <button type='button' onClick={() => actualizarGrillaIndicadores()} className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
              Aplicar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
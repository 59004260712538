import React from 'react'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  setSvgIcon?: boolean
}

const KTSVG: React.FC<Props> = ({ className = '', path, svgClassName = 'mh-50px', setSvgIcon = true }) => {
  return (
    <>
      {setSvgIcon ? <span className={`svg-icon ${className}`}>
        <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
      </span> :
        <span className={`${className}`}>
          <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
        </span>}
    </>
  )
}

export { KTSVG }

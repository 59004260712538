import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const descarFileNacional = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/Nacional/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileUsuarios = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/Usuarios/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileRegional = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/Regional/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileOficina = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/Oficina/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileEvolucion = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/Evolucion/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileResultadoRegional = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/ResultadoRegional/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descarFileResultadoOficina = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/ResultadoOficina/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const descargaReportePorCategoria = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/ReporteGeneral/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const getCumplimientosReporte= async (data) => {
    return axios.post(`${urlApi}/api/Reporteria/CumplimientosReporteGeneral`, data)
        .then((res) => { return res.data })
}

export const descargaReportePorCentroResponsable = async (extension, filtros) => {
    try {
        return axios.post(`${urlApi}/api/Reporteria/ReporteCentroResponsable/${extension}`, filtros,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}
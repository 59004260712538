import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const getPeriodosIndicador = async (id) => {
    return axios.get(`${urlApi}/api/util/PeriodosIndicador/${id}`)
        .then((res) => { return res.data })
}

export const getCamposCreacionUsuario = async () => {
    return axios.get(`${urlApi}/api/util/camposCreacionUsuario`)
        .then((res) => { return res.data })
}

export const getCamposCreacionIndicadores = async () => {
    return axios.get(`${urlApi}/api/util/CamposCreacionindicadores`)
        .then((res) => { return res.data })
}

export const getFiltrosReporteria = async () => {
    return axios.get(`${urlApi}/api/util/filtrosReporteria`)
        .then((res) => { return res.data })
}

export const getIndicadores = async (agno) => {
    return axios.get(`${urlApi}/api/util/indicadoresAgno/${agno}`)
        .then((res) => { return res.data })
}

export const getIndicadoresCentroResponsable = async (idCentroResponsable) => {
    return axios.get(`${urlApi}/api/util/indicadores/${idCentroResponsable}`)
        .then((res) => { return res.data })
}

export const DefinirTabActivo = (tabActivoActual, data) => {
    if (data?.length > 0) {
        if (tabActivoActual === 0) {
            return data[0].idCategoria
        }
        else if (data.some(x => x.idCategoria === tabActivoActual)) {
            return tabActivoActual
        }
        else {
            return data[0].idCategoria
        }
    }
    else {
        return 0
    }
}

export const CalcularTotalPonderado = (data) => {
    let total = data?.reduce((n, { valorPonderado }) => n + valorPonderado, 0)?.toFixed(2) ?? 0

    if (total === '99.99')
        total = 100

    return total
}

export const MayusculaPrimeraLetra = (palabra) => {
    if (palabra) {
        const arr = palabra.toLowerCase().split(" ");

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        return arr.join(" ");
    }

    return palabra
}

export const RemoveIdCentroResponsable = (nombre) => {
    if (nombre) {
        let partes = nombre.split('-')

        return partes[1].trim()
    }
    else {
        return nombre
    }
}
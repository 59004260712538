import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const getDetalleTotalIndicador = async (idIndicador) => {
    return axios.get(`${urlApi}/api/AdminIndicador/Detalle/${idIndicador}`)
        .then((res) => { return res.data })
}

export const saveNuevoIndicadores = async (data) => {
    try {
        return axios.post(`${urlApi}/api/AdminIndicador/Crear`, data,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const saveCompromisosNuevoIndicadores = async (data) => {
    return axios.post(`${urlApi}/api/AdminIndicador/CrearCompromisos`, data)
        .then((res) => { return res.data })
}

export const updateIndicador = async (data) => {
    return axios.post(`${urlApi}/api/AdminIndicador/Actualizar`, data)
        .then((res) => { return res.data })
}

export const UpdateMetasParciales = async (data) => {
    return axios.post(`${urlApi}/api/AdminIndicador/UpdateMetasParciales`, data)
        .then((res) => { return res.data })
}

export const getMetasParcialCompromiso = async (idCompromiso) => {
    return axios.get(`${urlApi}/api/AdminIndicador/MetasParciales/${idCompromiso}`)
        .then((res) => { return res.data })
}
// @ts-ignore
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ValidarDecimalGrilla } from '../../../services/helperService';

const TablaPonderadosIndicador = ({ data }: any) => {
    const colums: any = [
        {
            dataField: 'centroResponsable',
            text: 'Centro Responsable',
            sort: false,
            editable: () => { return false; }
            // formatter: (cell, row) => daFormatoCeldaTabla(row, cell, item.schema.data_type, alias, item.field, item.meta?.display),
        },
        {
            dataField: 'categoria',
            text: 'Categoria',
            sort: false,
            editable: () => { return false; }
        },
        {
            dataField: 'ponderador',
            text: 'Ponderador',
            sort: false,
            validator: (newValue: any, row: any, column: any) => {
                return ValidarDecimalGrilla(newValue)
            }
        }
    ]

    const customTotal = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} filas.
        </span>
    );

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            row.fueActualizado = true
        }
    });


    return (
        <div className='table-responsive'>
            <BootstrapTable
                cellEdit={cellEdit}
                keyField={'idCompromisoIndicador'}
                data={data}
                columns={colums}
                rowClasses="custom-row-class"
                pagination={paginationFactory(
                    {
                        withFirstAndLast: true,
                        page: 1,
                        sizePerPage: 10,
                        lastPageText: '››',
                        firstPageText: '‹‹',
                        prePageText: '‹',
                        nextPageText: '›',
                        showTotal: true,
                        hideSizePerPage: true,
                        paginationTotalRenderer: customTotal,
                    })}
                classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
            />
        </div>
    )
}

export default TablaPonderadosIndicador

import { MenuItem } from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Nivel Central' to='/dashboard' icon='/media/icons/duotune/art/grafico.svg' />
      <MenuItem title='Nivel Regional' to='/regional' icon='/media/icons/duotune/art/grafico.svg' />
      <MenuItem title='Nivel de Oficinas' to='/oficina' icon='/media/icons/duotune/art/grafico.svg' />
    </>
  )
}

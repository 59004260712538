/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { CrearIndicador } from '../../components/AdminIndicadores/CrearIndicador'

const AdminUsuarioPage: FC = () => (
    <>
        <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
                <CrearIndicador></CrearIndicador>
            </div>
        </div>
    </>
)

const CrearIndicadorWrapper: FC = () => {
    const intl = useIntl()

    return (
        <>
            <React.Fragment>
                <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Administración' })}</PageTitle>
                <AdminUsuarioPage />
            </React.Fragment>
        </>
    )
}

export { CrearIndicadorWrapper }

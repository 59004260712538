import { FC } from 'react'

type Props = {
    habilitado?: boolean
}

const EstadoUsuarioCell: FC<Props> = ({ habilitado }) => (
    <>
        {habilitado ? <div className='badge badge-light-success fw-bolder'>Habilitado</div> :
            <div className='badge badge-light-danger fw-bolder'>Inhabilitado</div>}
    </>
)

export { EstadoUsuarioCell }

import axiosConfig from '../config/axiosGraph'
const axios = axiosConfig.iAxios
const urlGraph = 'https://graph.microsoft.com/v1.0'

export const getFotoUsuarioSession = async () => {
    return axios.get(`${urlGraph}/me/photos/240x240/$value`,
        { responseType: 'arraybuffer', reponseEncoding: 'binary' }).then((res) => { return res })
}

export const getDatosUsuarioAd = async (mailUsuario) => {
    return axios.get(`${urlGraph}/users?$filter=startswith(mail,'${mailUsuario}')&$select=displayName,id`)
        .then((res) => { return res.data })
}

export const getFotoUsuarioACrear = async (idUsuario) => {
    return axios.get(`${urlGraph}/users/${idUsuario}/photo/$value`,
        { responseType: 'arraybuffer', reponseEncoding: 'binary' }).then((res) => { return res })
}
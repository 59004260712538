import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useState } from 'react';

type Props = {
    show: boolean
    handleClose: () => void,
    descargarDoc: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalTipoArchivo = ({ show, handleClose, descargarDoc }: Props) => {
    const [formato, setFormato] = useState<string>('pdf')

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header p-5'>
                <h3 className='text-gray-600 text-hover-primary fs-2 fw-semibold'>Formato de Descarga</h3>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row g-5 g-xxl-8'>
                    <div className='row pt-4' style={{ justifyContent: 'center' }}>
                        <div className='col-md-6'>
                            <div>
                                <select
                                    className='form-select'
                                    onChange={e => setFormato(e.target.value)}
                                    defaultValue={formato}
                                >
                                    <option selected={true} value='pdf'>PDF</option>
                                    <option value='xlsx'>Excel</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center pt-3'>
                    <button
                        type='reset'
                        onClick={() => handleClose()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary'
                        data-kt-users-modal-action='button'
                        onClick={() => descargarDoc(formato)}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                        <span className='indicator-label'>Exportar</span>
                    </button>
                </div>
            </div>
        </Modal >,
        modalsRoot
    )
}

export { ModalTipoArchivo }
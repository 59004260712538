export const perfilesId = {
    ADMINISTRADOR: 1,
    GESTOR: 2,
    JEFE_CENTRO: 3,
    SUPERVISOR: 4,
    AUDITOR: 5,
    USUARIO: 7
}

export const tipoComentarioId = {
    JUSTIFICACION: 1,
    ANALISIS_CONTROL: 2,
    VALIDACION_SUPERVISOR: 3,
    RECHAZO_SUPERVISOR: 4,
    VALIDACION_JEFE_CENTRO: 5,
    JUSTIFICACION_POR_SUPERVISOR: 6,
}

export const tipoValidacionId = {
    VALIDADO: 1,
    VALIDADO_OBSERVACIONES: 2,
    RECHAZADO: 3
}

export const codigoPerfiles = {
    ADMINISTRADOR: 'ADM',
    GESTOR: 'GES',
    JEFE_CENTRO: 'JCR',
    SUPERVISOR: 'SUP',
    USUARIO: 'USU',
    AUDITOR: 'AUD',
}

export const claimsValue = {
    ROL: 'rol_usuario'
}

export const nivelRol = {
    NACIONAL: 1,
    REGIONAL: 2,
    OFICINA: 3,
    NACIONAL_INGRESO_ANALISIS: 4,
    VISOR: 5
}

export const TipoIndicadorId = {
    NACIONAL: 1,
    REGIONAL: 2,
}

export const TipoCargaId = {
    AUTOMATIZADO: 1,
    MANUAL: 2,
}

export const CategoriasId = {
    FORMULARIO_H: 2,
    LEY_21: 8,
    PMG: [7, 10, 11, 12],
}
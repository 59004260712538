import clsx from 'clsx'
import React, { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../helpers'
import { useLayout } from '../../../core/LayoutProvider'
import { usePageData } from '../../../core/PageData'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const DefaultTitle: FC = () => {
  const paginaRedireccion = useSelector((store: any) => store.appReducer.urlRetorno)
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData()
  const { config, classes } = useLayout()
  const navigate = useNavigate()

  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >

      {pageTitle !== 'Dashboard' && !window.location.pathname.includes('Ponderado')?
        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1 m-1">
          <li className="breadcrumb-item text-muted mr-2 pr-2">
            <button className='dot' onClick={() => navigate(paginaRedireccion)}>

              <KTSVG
                setSvgIcon={false}
                path={'/media/svg/card-logos/volver.svg'}
                className='svg-icon-3'
              />
            </button>
          </li>
        </ul> : null}

      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 m-1'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}  
    </div>
  )
}

export { DefaultTitle }

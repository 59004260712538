import { KTSVG } from "../../../_metronic/helpers"
import { FiltroPeriodo } from "../Filtros/FiltroPeriodo";
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";

const HeaderListaPeriodo = ({ filtrosSeleccionados, getPeriodos }: any) => {
  const navigate = useNavigate();
  const [showFiltros, setShowFiltros] = useState<boolean>(false)

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>
        <div className='d-flex align-items-center position-relative my-1'>
          {/* <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            className='form-control form-control-solid w-250px ps-14'
            placeholder='Buscar usuario'
            onChange={(e) => onBuscarUsuario(e.target.value)}
          /> */}
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button
            type='button'
            className='btn btn-light-primary me-3'
            onClick={() => setShowFiltros(!showFiltros)}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
            Filtros
          </button>
          {/* <button type='button' className='btn btn-primary' onClick={() => navigate('/administracion/crearUsuario')}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Crear Período
          </button> */}
        </div>
      </div>
      {showFiltros ? <FiltroPeriodo filtrosSeleccionados={filtrosSeleccionados} onBuscar={getPeriodos}></FiltroPeriodo> : null}
    </div>
  )
}

export { HeaderListaPeriodo }

import React, { useState, useEffect } from 'react'
import { editarIndicadorInitValues as initialValues, EditarIndicadorModel } from '../../modules/accounts/components/settings/SettingsModel'
import { useFormik, FormikErrors } from 'formik'
import { toast } from "react-toastify"
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch } from 'react-redux'
import { getIndicadores, getCamposCreacionIndicadores } from '../../../services/utilService'
import CustomSelect from '../Helper/CustomSelect'
import { getDetalleTotalIndicador } from '../../../services/AdminIndicador'
import TablaCompromisosIndicador from '../tablas/TablaCompromisosIndicador'
import { TipoCargaId } from '../../interfaces/enumeradores'
import TablaPonderadosIndicador from '../tablas/TablaPonderadosIndicador'
import { updateIndicador } from '../../../services/AdminIndicador'

const EditarIndicador: React.FC = () => {
    const dispatch = useDispatch()
    const [indicadores, setIndicadores] = useState<any>(null)
    const [ponderados, setPonderados] = useState<any>(null)
    const [compromisos, setCompromisos] = useState<any>(null)
    const [campos, setCampos] = useState<any>(null)
    const agnoActual = new Date().getFullYear()
    const agnosSeleccionar = [{ label: agnoActual.toString(), value: agnoActual }, { label: (agnoActual - 1).toString(), value: agnoActual - 1 }]

    useEffect(() => {
        const fetchData = async () => {
            dispatch(isLoading(true))
            Promise.all([getIndicadores(agnoActual), getCamposCreacionIndicadores()
            ]).then(values => {
                setIndicadores(values[0])
                setCampos(values[1])

            }).catch(reason => {
                console.log(reason)
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }).finally(() => dispatch(isLoading(false)));
        }
        fetchData()
    }, [])

    const validate = (values: EditarIndicadorModel) => {
        let errors: FormikErrors<EditarIndicadorModel> = {}

        if (values.nombre === '') {
            errors.nombre = 'Campo obligatorio.'
        }

        if (values.version === '') {
            errors.version = 'Campo obligatorio.'
        }

        if (values.etiqueta === '') {
            errors.etiqueta = 'Campo obligatorio.'
        }

        if (values.descripcion === '') {
            errors.descripcion = 'Campo obligatorio.'
        }

        if (values.formula === '') {
            errors.formula = 'Campo obligatorio.'
        }

        if (values.ordenDespliegue === '') {
            errors.ordenDespliegue = 'Campo obligatorio.'
        }

        if (values.idCategoria === null || values.idCategoria?.length === 0) {
            errors.idCategoria = 'Campo obligatorio.'
        }

        return errors;
    }

    const formik = useFormik({
        initialValues,
        validate,
        onSubmit: (async (values, actions) => {
            try {
                dispatch(isLoading(true))
                const compromisosAct: any = compromisos.filter((x: any) => x.fueActualizado)?.map(function (item: any) {
                    return {
                        ...item,
                        meta: parseFloat(item.meta)
                    }
                });
                const ponderadosAct: any = ponderados.filter((x: any) => x.fueActualizado)?.map(function (item: any) {
                    return {
                        ...item,
                        ponderador: parseFloat(item.ponderador)
                    }
                });

                //se convierte baseFija
                values.baseFija = values.baseFija?.toString() === '' ? null : values.baseFija

                const data = {
                    ...values,
                    compromisos: compromisosAct,
                    ponderados: ponderadosAct,
                }

                const response = await updateIndicador(data)
                toast.success('Registro actualizado correctamente.')
                actions.resetForm()
                setCompromisos(null)
                setPonderados(null)
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        })
    })

    const onChangeCustomSelect = (value: any, isMulti: boolean, inputName: string) => {
        if (isMulti) {
            const values = value.map((item: any) => item.value)

            formik.setFieldValue(inputName, values)
        }
        else {
            formik.setFieldValue(inputName, value.value)
        }
    }

    const onSelectAgno = async (value: any) => {
        try {
            dispatch(isLoading(true))
            const data = await getIndicadores(value.value)
            setIndicadores(data)
            formik.resetForm()
            setCompromisos(null)
            setPonderados(null)
            formik.setFieldValue('agnoFiltro', value.value)
        }
        catch {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }

    const onSelectIndicador = async (value: any) => {
        try {
            dispatch(isLoading(true))
            const data = await getDetalleTotalIndicador(value.value)
            updateFormValues(data, value.value)
            setCompromisos(data.compromisos)
            setPonderados(data.ponderados)
        }
        catch {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }

    const updateFormValues = (data: any, idIndicador: number) => {
        formik.setValues({
            agnoFiltro: data.agno,
            idIndicador: idIndicador,
            nombre: data.nombre,
            version: data.version,
            etiqueta: data.etiqueta,
            descripcion: data.descripcion,
            idArea: data.idArea,
            idTipoValor: data.idTipoValor,
            formula: data.formula,
            ordenDespliegue: data.ordenDespliegue,
            agno: data.agno,
            lectura: data.lectura,
            idTipoIndicador: data.idTipoIndicador,
            queryFechaCorte: data.queryFechaCorte,
            query: data.query,
            idTipoCarga: data.idTipoCarga,
            baseFija: data.baseFija ?? '',
            idCategoria: data.idCategoria
        }, false)
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Editar indicador</h3>
                </div>
            </div>
            <div className='modal-body mx-5 mx-xl-9 my-1'>
                <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className='row mb-6'>
                        <label className='col-lg-2 col-form-label required fw-semibold fs-6'>Año</label>
                        <div className='col-lg-4'>
                            <div className='row'>
                                <div className='col-lg-12 fv-row'>
                                    <CustomSelect
                                        className='fw-semibold'
                                        value={formik.values.agnoFiltro}
                                        noOptionsMessage={() => "No se encontraron resultados."}
                                        placeholder={'Seleccione año...'}
                                        onChange={(value: any) => onSelectAgno(value)}
                                        options={agnosSeleccionar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-2 col-form-label required fw-semibold fs-6'>Indicador</label>

                        <div className='col-lg-10'>
                            <div className='row'>
                                <div className='col-lg-12 fv-row'>
                                    <CustomSelect
                                        className='fw-semibold'
                                        value={formik.values.idIndicador}
                                        noOptionsMessage={() => "No se encontraron resultados."}
                                        placeholder={'Seleccione indicador...'}
                                        onChange={(value: any) => onSelectIndicador(value)}
                                        options={indicadores}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {formik.values.idIndicador ? <>
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Nombre</label>
                            <div className='col-lg-10 col-md-10'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='nombre'
                                            {...formik.getFieldProps('nombre')}
                                        />
                                        {formik.touched.nombre && formik.errors.nombre && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.nombre}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Versión</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Versión'
                                            {...formik.getFieldProps('version')}
                                        />
                                        {formik.touched.version && formik.errors.version && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.version}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Etiqueta</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Etiqueta'
                                            {...formik.getFieldProps('etiqueta')}
                                        />
                                        {formik.touched.etiqueta && formik.errors.etiqueta && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.etiqueta}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Descripción</label>
                            <div className='col-lg-10 col-md-10'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Descripción'
                                            {...formik.getFieldProps('descripcion')}
                                        />
                                        {formik.touched.descripcion && formik.errors.descripcion && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.descripcion}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Area</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.idArea}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione Area...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, false, 'idArea')}
                                            options={campos.areas}
                                        />
                                        {formik.touched.idArea && formik.errors.idArea && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.idArea}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Tipo de valor</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.idTipoValor}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione tipo de Valor...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, false, 'idTipoValor')}
                                            options={campos.tiposValor}
                                        />

                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Formula</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Formula'
                                            {...formik.getFieldProps('formula')}
                                        />
                                        {formik.touched.formula && formik.errors.formula && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.formula}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Orden de despliegue</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Orden de despliegue'
                                            {...formik.getFieldProps('ordenDespliegue')}
                                        />
                                        {formik.touched.ordenDespliegue && formik.errors.ordenDespliegue && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ordenDespliegue}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Año</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            readOnly={true}
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Año'
                                            {...formik.getFieldProps('agno')}
                                        />
                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Lectura</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.lectura}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione lectura...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, false, 'lectura')}
                                            options={campos.lecturas}
                                        />

                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Tipo de indicador</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.idTipoIndicador}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione tipo de indicador...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, false, 'idTipoIndicador')}
                                            options={campos.tiposIndicador}
                                        />
                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Tipo de carga</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.idTipoCarga}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione tipo de carga...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, false, 'idTipoCarga')}
                                            options={campos.tiposCargas}
                                        />


                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Query</label>
                            <div className='col-lg-10 col-md-10'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <textarea
                                            className='form-control pe-10 resize-none min-h-25px'
                                            data-kt-autosize='true'
                                            rows={3}
                                            disabled={true}
                                            placeholder='Query'
                                            {...formik.getFieldProps('query')}
                                        />
                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Query Fecha corte</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            disabled={true}
                                            type='text'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Fecha Corte'
                                            {...formik.getFieldProps('queryFechaCorte')}
                                        />
                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>

                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Base fija</label>
                            <div className='col-lg-4 col-md-4'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            disabled={formik.values.idTipoCarga === TipoCargaId.AUTOMATIZADO}
                                            type='number'
                                            className='form-control form-control-lg mb-3 mb-lg-0'
                                            placeholder='Base fija'
                                            {...formik.getFieldProps('baseFija')}
                                        />
                                        {/* {formik.touched.nombre && formik.errors.nombre && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                                        </div>
                                    )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Categoría</label>
                            <div className='col-lg-10 col-md-10'>
                                <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                        <CustomSelect
                                            className='fw-semibold'
                                            value={formik.values.idCategoria}
                                            noOptionsMessage={() => "No se encontraron resultados."}
                                            placeholder={'Seleccione categoría...'}
                                            onChange={(value: any) => onChangeCustomSelect(value, true, 'idCategoria')}
                                            options={campos.categorias}
                                            isMulti={true}
                                        />
                                        {formik.touched.idCategoria && formik.errors.idCategoria && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.idCategoria}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <div className='row'>
                                <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Ponderados</label>

                                <div className='col-lg-10 col-md-10'>
                                    <TablaPonderadosIndicador
                                        data={ponderados}
                                    ></TablaPonderadosIndicador>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='row mb-6'>
                            <div className='row'>
                                <label className='col-lg-2 col-md-2 col-form-label required fw-semibold fs-6'>Compromisos</label>

                                <div className='col-lg-10 col-md-10'>
                                    <TablaCompromisosIndicador
                                        data={compromisos}
                                    ></TablaCompromisosIndicador>
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        {/* ----------------------------------------------------------- */}
                        <div className='card-footer d-flex justify-content-end py-6'>
                            <button type='button' onClick={() => formik.handleSubmit()} className='btn btn-primary'>
                                Guardar
                            </button>
                        </div>

                    </> : null}
                </form>
            </div>


        </div>
    )
}

export { EditarIndicador }

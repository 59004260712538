import Spinner from 'react-bootstrap/Spinner';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const Loader = () => {
    return (
        <>
            <div className="load-screen">
                <div className="global-loader">
                    <div className="global-loader">
                        <img src={toAbsoluteUrl('/media/avatars/loading-dt.gif')} alt='Cargando' />
                        <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>Cargando...</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loader;
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ValidarDecimalGrilla } from '../../../services/helperService';
import { ModalMetasParcial } from '../Modales/ModalEditarMetasParcial';
import { useState } from 'react';
import { getMetasParcialCompromiso } from '../../../services/AdminIndicador';
import { useDispatch, useSelector } from 'react-redux';
import { isLoading } from '../../../redux/AppSlice';
import { toast } from 'react-toastify'

const TablaCompromisosIndicador = ({ data }: any) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [metasParciales, setMetasParciales] = useState<any>(null)


    const onClose = () => {
        setShowModal(false)
    }

    const colums: any = [
        {
            dataField: 'centroResponsable',
            text: 'Centro Responsable',
            sort: false,
            editable: false,
        },
        {
            dataField: 'meta',
            text: 'Meta',
            sort: false,
            validator: (newValue: any, row: any, column: any) => {
                return ValidarDecimalGrilla(newValue)
            }
        },
        {
            text: 'Metas parciales',
            dataField: '',
            width: '25%',
            dataSort: false,
            editable: false,
            formatter: (cell: any, row: any) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <a
                        style={{ cursor: 'pointer' }}
                        className='text-dark fw-semibold text-hover-primary mb-1 fs-6'
                        onClick={() => onVerMetasParciales(row.idCompromiso, row.centroResponsable)}
                    >
                        Ver
                    </a>
                </div>
            )
        },
    ]

    const onVerMetasParciales = async (idCompromiso: number, centroResponsable: string) => {
        try {
            dispatch(isLoading(true))
            const data = await getMetasParcialCompromiso(idCompromiso)
            setMetasParciales({ centroResponsable, data })
            setShowModal(true)
        }
        catch {
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
        finally {
            dispatch(isLoading(false))
        }
    }

    const customTotal = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} filas.
        </span>
    );

    const cellEdit = cellEditFactory({
        mode: 'click',
        blurToSave: true,
        afterSaveCell: (oldValue: any, newValue: any, row: any, column: any) => {
            row.fueActualizado = true
        }
    });


    return (
        <div className='table-responsive'>
            <ModalMetasParcial
                data={metasParciales}
                handleClose={onClose}
                show={showModal}
            ></ModalMetasParcial>
            <BootstrapTable
                cellEdit={cellEdit}
                keyField={'idCompromiso'}
                data={data}
                columns={colums}
                rowClasses="custom-row-class"
                pagination={paginationFactory(
                    {
                        withFirstAndLast: true,
                        page: 1,
                        sizePerPage: 10,
                        lastPageText: '››',
                        firstPageText: '‹‹',
                        prePageText: '‹',
                        nextPageText: '›',
                        showTotal: true,
                        hideSizePerPage: true,
                        paginationTotalRenderer: customTotal,
                    })}
                classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
            />
        </div>
    )
}

export default TablaCompromisosIndicador

import { createRoot } from 'react-dom/client'

//unRegister para evitar que los usuarios tengan problemas con cache de la app
import { unregister } from './app/registerServiceWorker'

// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'

// -- Redux
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux';
import todosReducer from './redux/AppSlice'

// --msal
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType, Configuration } from "@azure/msal-browser";
import { msalConfig } from './config/authConfig'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/app.scss'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
// import { setupAxios } from './app/modules/auth'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// setupAxios(axios)
Chart.register(...registerables)

export const store = configureStore({
  reducer: {
    appReducer: todosReducer
  },
  devTools: process.env.REACT_APP_HABILITA_DEV_TOOL === 'true',
})

const queryClient = new QueryClient()
const container = document.getElementById('root')
const msalInstance = new PublicClientApplication(msalConfig as Configuration);


if (container) {
  createRoot(container).render(
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
            <AppRoutes />
          </MetronicI18nProvider>
        </QueryClientProvider>
      </Provider>
    </MsalProvider>
  )

  unregister()
}


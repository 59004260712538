/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { indicadoresNacionalesAction, setTabActivo, isLoading, cleanIndicadorSeleccionado, setFiltrosSeleccionados } from '../../redux/AppSlice'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver';
import { descarFileNacional } from '../../services/ReporteriaService';
import { getIndicadoresNacionales } from '../../services/IndicadorService';
import { FiltroNacional } from './Filtros/FiltroNacional'
import { setCentroNacionalDefecto } from '../../services/helperService';
import IndicadoresNacionalTabla from './tablas/IndicadoresNacionalesTabla'
import { ModalTipoArchivo } from './Modales/ModalTipoArchivo';
import { DefinirTabActivo } from '../../services/utilService';

type Props = {
    className: string
}

const IndicadoresNacional = ({ className }: Props) => {
    const dispatch = useDispatch()
    const tabActivo = useSelector((store: any) => store.appReducer.tabsActivos.nacional)
    const seleccionfiltros = useSelector((store: any) => store.appReducer.filtrosReporteSeleccionado)
    const usuario = useSelector((store: any) => store.appReducer.usuario)
    const filtros = useSelector((store: any) => store.appReducer.filtrosReportes)
    const [showFiltros, setShowFiltros] = useState<any>(null)
    const [showModalExport, setShowModalExport] = useState<boolean>(false)
    const indicadores = useSelector((store: any) => store.appReducer.indicadoresNacionales)
    const gestorVeSoloSusCompromisos = useSelector((store: any) => store.appReducer.gestorVeSoloSusCompromisos)

    useEffect(() => {
        const fetchData = async () => {
            dispatch(cleanIndicadorSeleccionado(null))

            if (usuario) {
                dispatch(isLoading(true))
                let idCentroNacional = setCentroNacionalDefecto(usuario, filtros.centrosNacionales, seleccionfiltros.idCentroNacional)
                idCentroNacional = idCentroNacional != '' ? idCentroNacional : null
                const compromiso = filtros?.compromisos.filter((x: any) => x.esNacional && x.value == seleccionfiltros.idCategoria)[0]?.value ?? null

                const filtro = {
                    agno: seleccionfiltros.agno,
                    mes: seleccionfiltros.mes,
                    idCategoria: compromiso,
                    idCentroNacional: idCentroNacional,
                    gestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
                }

                dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, ...filtro }))

                Promise.all([getIndicadoresNacionales(filtro)
                ]).then(values => {
                    const indicadores = values[0]
                    const tab: number = DefinirTabActivo(tabActivo, indicadores)

                    dispatch(setTabActivo({ nacional: tab }))
                    dispatch(indicadoresNacionalesAction(indicadores))
                    dispatch(isLoading(false))
                }).catch(reason => {
                    console.log(reason)
                    dispatch(isLoading(false))
                    toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                });
            }
        }
        fetchData()
    }, [usuario])

    const onExportar = (tipoDoc: string) => {
        dispatch(isLoading(true))

        const filtro = {
            ...seleccionfiltros,
            gestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
        }

        Promise.resolve(
            descarFileNacional(tipoDoc, filtro)
        ).then((response: any) => {
            var blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, `Nivel_Nacional.${tipoDoc}`);
            dispatch(isLoading(false))
            setShowModalExport(false)
        }).catch(reason => {
            console.log(reason)
            dispatch(isLoading(false))
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        })
        // 
    }

    return (
        <div className={`card ${className}`}>
            <ModalTipoArchivo
                show={showModalExport}
                descargarDoc={onExportar}
                handleClose={() => setShowModalExport(!showModalExport)}
            ></ModalTipoArchivo>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>
                        <div style={{ float: 'left', paddingRight: '5px' }}><img src={toAbsoluteUrl('/media/icons/duotune/art/agenda.svg')} /></div>
                        Listado de Indicadores</span>
                    <span className='sub-title fw-semibold fs-7 ml-2'>Nivel Nacional</span>
                </h3>
                <div className='card-toolbar'>
                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/svg/general/calendario.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Año
                        </span>
                        <span style={{ textAlign: 'right' }} className='sub-title fw-semibold fs-7 ml-2'>{seleccionfiltros?.agno}</span>
                    </h3>
                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/svg/general/calendario.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Período
                        </span>
                        <span style={{ textAlign: 'center' }} className='sub-title fw-semibold fs-7 ml-2'>{filtros?.periodos?.filter((x: any) => x.value == seleccionfiltros?.mes)[0]?.glosa}</span>
                    </h3>

                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/icons/duotune/art/agenda.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Centro Responsable
                        </span>
                        <span style={{ textAlign: 'right' }} className='sub-title fw-semibold fs-7 ml-2'>{filtros?.centrosNacionales?.filter((x: any) => x.value == seleccionfiltros?.idCentroNacional)[0]?.glosa ?? 'Todos'}</span>
                    </h3>

                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/icons/duotune/art/agenda.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Compromiso
                        </span>
                        <span style={{ textAlign: 'center' }} className='sub-title fw-semibold fs-7 ml-2'>{filtros?.compromisos?.filter((x: any) => x.value == seleccionfiltros?.idCategoria)[0]?.glosa ?? 'Todos'}</span>
                    </h3>

                    <h3 className='card-title align-items-center flex-column'>
                        <button className='dot'
                            onClick={() => setShowFiltros(!showFiltros)}
                        >
                            <KTSVG
                                setSvgIcon={false}
                                path={showFiltros ? '/media/svg/general/close.svg' : '/media/svg/general/filtro.svg'}
                                className='svg-icon-3'
                            />
                        </button>
                        <span className='text-muted fw-semibold fs-7 ml-2'>{showFiltros ? 'Desactivar filtros' : 'Activar filtros'}</span>
                    </h3>
                </div>
            </div>
            <div className='card-header border-0' style={{ minHeight: '10px' }}>
                <h3 className='align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Información acumulada a {filtros?.periodos?.filter((x: any) => x.value == seleccionfiltros?.mes)[0]?.glosa} del {seleccionfiltros?.agno} </span>
                </h3>
            </div>
            <div className='row'><div className='col-md-10 offset-md-1'><hr></hr></div></div>
            <div className='card-body pt-0 pb-0'>
                {showFiltros ?
                    <FiltroNacional /> : null}
            </div>
            <div className='card-body pt-0'>
                <div className='d-flex overflow-auto'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        {indicadores?.map((item: any, index: number) => (
                            <li className='nav-item' key={index} onClick={() => dispatch(setTabActivo({ nacional: item.idCategoria }))}>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (tabActivo == item.idCategoria && 'active')
                                    }
                                >     {item.nombreCategoria}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='card-body pt-0 pb-0'>
                <IndicadoresNacionalTabla
                    idCategoria={tabActivo}
                ></IndicadoresNacionalTabla>
            </div>

            {indicadores ?
                <div className='card-header border-0 pt-0'>
                    <div className='card-title'></div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <button type='button' onClick={() => setShowModalExport(true)} className='btn btn-sm btn-primary'>
                                <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                Exportar
                            </button>
                        </div>
                    </div>
                </div> : null}
        </div>
    )
}

export default IndicadoresNacional

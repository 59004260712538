import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useMsal } from "@azure/msal-react";
import { graphRequest } from '../../../../config/authConfig'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Ingrese Email')
})

const initialValues = {
  email: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [tieneAcceso, setTieneAcceso] = useState(true)
  const { instance } = useMsal()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTieneAcceso(true)
      try {
        const tieneAcceso = (await axios.get(`${process.env.REACT_APP_API_INDICADORES}/api/usuario/TieneAcceso/${values.email}`)).data

        if (tieneAcceso) {
          instance.loginRedirect({ ...graphRequest, loginHint: values.email }).catch(e => {
            console.error(e);
          });
        }
        else {
          setTieneAcceso(false)
        }
      } catch (error) {
        console.error(error)
      }
      finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Software de Planificación y Control</h1>
      </div>
      {/* begin::Heading */}
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label'>Email:</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg cajaLogin',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5 MoradoOscuro'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Ingresar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Cargando...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {!tieneAcceso ?
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>Usuario no autorizado.</div>
          </div> : null}
      </div>
      {/* end::Action */}
    </form>
  )
}

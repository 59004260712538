import React, { useState, useEffect } from 'react'
import { KTCard } from '../../../_metronic/helpers'
import { HeaderListaPeriodo } from '../Administracion/HeaderListaPeriodo'
import { TablaPeriodos } from '../tablas/TablaPeriodos'
import { getPeriodos } from '../../../services/AdminPeriodo'
import dayjs from "dayjs"

const AdminPeriodo: React.FC = () => {
    const [periodos, setPeriodos] = useState<any>(null)
    const [filtros, setFiltros] = useState<any>(null)

    useEffect(() => {
        onFiltrar({ Estado: '', Periodo: null })
    }, [])

    const onFiltrar = async (filtros: any) => {
        setFiltros(filtros)
        const filtrosAdaptado: any = { ...filtros }

        if (filtrosAdaptado.Periodo) {
            filtrosAdaptado.Periodo = dayjs(`${filtrosAdaptado.Periodo}-01`).format('YYYY-MM-DD')
        }
        else if (filtrosAdaptado.Periodo === '') {
            filtrosAdaptado.Periodo = null
        }

        if (filtrosAdaptado.Estado) {
            filtrosAdaptado.Estado = filtrosAdaptado.Estado === 1 ? 'Abierto' : 'Cerrado'
        }
        else {
            filtrosAdaptado.Estado = null
        }

        fetchData(filtrosAdaptado)
    }

    const fetchData = async (filtros: any) => {
        const data = await getPeriodos(filtros)

        setPeriodos(data)
    }

    const onUpdatePeriodo = async () => {
        const data = await getPeriodos(filtros)

        setPeriodos(data)
    }

    return (
        <>
            <KTCard>
                <HeaderListaPeriodo
                    filtrosSeleccionados={filtros}
                    getPeriodos={onFiltrar}
                />
                <TablaPeriodos
                    data={periodos}
                    updateData={onUpdatePeriodo}
                />
            </KTCard>
        </>
    )
}

export { AdminPeriodo }

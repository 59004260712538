/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { User } from '../../core/_models'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user?.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(`/media/${user.avatar}`)} alt={user.name} className='w-100' />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-3',
              `bg-light-warning `,
              `text-warning`
            )}
          >
            {user?.initials?.label || user?.nombre?.charAt(0)}
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <span className='text-muted fw-semibold text-muted d-block fs-7'>
        {user.nombre || user.name}
      </span>
      {/* 
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {user.nombre || user.name}
      </a> */}
    </div>
  </div>
)

export { UserInfoCell }

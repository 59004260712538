/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes, Navigate } from 'react-router-dom'
import { Login } from './components/Login'
import { KTSVG } from '../../../_metronic/helpers'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useIsAuthenticated } from "@azure/msal-react";

const AuthLayout = () => {
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  const onClickRedSocial = (link: string) => {
    window.open(link, '_blank');
  }

  return (
    <>
      {isAuthenticated ? <>
        <Navigate to='/puente' />
      </> :
        <div className='fondoLogin d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        // style={{
        //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14_noche.png')})`,
        // }}
        >
          {/* begin::Content */}
          {/* <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'> */}
          <div className='d-flex flex-center flex-column flex-column-fluid pt-10'>
            {/* begin::Logo */}
            <a href='#' className='mb-6'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo_DT.svg')} className='LogoLogin' />
            </a>
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <Outlet />
            </div>

            <div className='d-flex flex-center flex-column-auto'>
              <div className='d-flex align-items-center fw-bold'>
                <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                  <div className="btn-group px-4 pt-10" role="group" aria-label="First group">
                    <button className='dotLogn' onClick={()=> onClickRedSocial('https://www.facebook.com/profile.php?id=100064883850198&paipv=0&eav=AfZ8sAmHY7FWICPdlOTbZpfhJRc_6hgxTXFuE_XhZ84tzsP9EdFNWhjNG7psRsy3seY&_rdr')}
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/logos/facebook.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className="btn-group px-4 pt-10" role="group" aria-label="Second group">
                    <button className='dotLogn' onClick={()=> onClickRedSocial('https://twitter.com/direcdeltrabajo?lang=es')}
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/logos/twitter.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className="btn-group px-4 pt-10" role="group" aria-label="Second group">
                    <button className='dotLogn' style={{ paddingTop: '5px' }} onClick={()=> onClickRedSocial('https://www.youtube.com/user/DireccionDelTrabajo')}
                    >
                      <KTSVG

                        setSvgIcon={false}
                        path={'/media/logos/youtube.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className="btn-group px-4 pt-10" role="group" aria-label="Third group">
                  <button className='dotLogn' onClick={()=> onClickRedSocial('https://www.linkedin.com/company/direcci-n-del-trabajo/?originalSubdomain=cl')}
                  style={{paddingTop:'2px', paddingLeft:'2px'}}
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/logos/linkedin.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='d-flex flex-center flex-column-auto p-10'>
              <div className='d-flex align-items-center fw-bold fs-6'>
                <div className='row'>
                  <div className='col-md-1 col-xs-2'>
                    <button className='dot'
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/svg/general/filtro.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className='col-md-2 col-xs-2'>
                    <button className='dot'
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/svg/general/filtro.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className='col-md-2 col-xs-2'>
                    <button className='dot'
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/svg/general/filtro.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                  <div className='col-md-2 col-xs-2'>
                    <button className='dot'
                    >
                      <KTSVG
                        setSvgIcon={false}
                        path={'/media/svg/general/filtro.svg'}
                        className='svg-icon-3'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div> */}


            {/* redes socailaes */}
            {/* <div className='mx-auto mt-4'>
              <div className='row'>
                <div className='col-md-1 col-xs-2'>
                  <a href='#'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/facebook.svg')} />
                  </a>
                </div>
                <div className='col-md-1 col-xs-2'>
                  <a href='#'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/linkedin.svg')} />
                  </a>
                </div>
                <div className='col-md-1 col-xs-2'>
                  <a href='#'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/youtube.svg')} />
                  </a>
                </div>
                <div className='col-md-1 col-xs-2'>
                  <a href='#'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/twitter.svg')} />
                  </a>
                </div>
              </div>
            </div> */}
            {/*fin redes socailaes */}

            <div className='mx-auto mt-4'>
              <div className='order-2 order-md-1'>
                <a href='#'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo_Govms.png')} className="logoGovms" />
                </a>
              </div>
            </div>
          </div>
          {/* end::Content */}
          {/* begin::Footer */}

          {/* <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
              <a href='#' className='text-muted text-hover-primary px-2'>
                About
              </a>

              <a href='#' className='text-muted text-hover-primary px-2'>
                Contact
              </a>

              <a href='#' className='text-muted text-hover-primary px-2'>
                 Contact Us 
              </a>
            </div>
          </div> */}
          {/* end::Footer */}
        </div>
      }
    </>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }

import axios from 'axios'
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, backendRequest } from '../config/authConfig';

const pca = new PublicClientApplication(msalConfig);

let iAxios = axios.create({
    defaultInterceptors: true,
    headers: { 'Content-Type': 'application/json; charset=utf-8' }
})

const getToken = async () => {
    const accounts = pca.getAllAccounts();
    let token = ''
    const request = {
        ...backendRequest,
        account: accounts[0]
    };
    await pca.acquireTokenSilent(request).then((response) => {
        token = response.accessToken
    }).catch((e) => {
        pca.acquireTokenRedirect(request).catch(e => {
            console.error(e);
        });
    });
    return token
}

iAxios.interceptors.request.use(
    async (config) => {
        try {
            let tokenJWT = await getToken()
            config.headers.common['Authorization'] = `Bearer ${tokenJWT}`
        }
        catch (error) {
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

iAxios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        console.log(error)
        return Promise.reject(error)
    }
)

const config = { iAxios }

export default config
import { useState, useRef } from 'react'
import { cargaInitValues as initialValues, IFormCarga } from '../../modules/accounts/components/settings/SettingsModel'
import { useFormik, FormikErrors } from 'formik'
import { saveCompromisosNuevoIndicadores } from '../../../services/AdminIndicador'
import { toast } from "react-toastify"
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch } from 'react-redux'
import { ModalErroresCompromisos } from '../Modales/ModalErroresCompromisos'

const FormCargaCompromisos: React.FC = () => {
    const [errorresValidacion, setErroresValidacion] = useState<any>(null)
    const [showModal, setShowModal] = useState<boolean>(false)

    const dispatch = useDispatch()

    const validate = (values: IFormCarga) => {
        let errors: FormikErrors<IFormCarga> = {};

        if (values.fileCompromisos === null) {
            errors.fileCompromisos = 'Campo obligatorio.'
        }

        return errors;
    }

    const inputRef = useRef<any>(null)

    const formik = useFormik<any>({
        initialValues,
        validate,
        onSubmit: async (values) => {
            try {
                dispatch(isLoading(true))

                const formData = new FormData()
                formData.append("Compromisos", values.fileCompromisos)
                const response = await saveCompromisosNuevoIndicadores(formData)

                if (response?.huboErrores) {
                    setErroresValidacion(response.resultado)
                    setShowModal(true)
                }
                else {
                    toast.success('Compromisos cargados correctamente.')
                }
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
                formik.setFieldValue('fileCompromisos', null, false)

                if (inputRef?.current)
                    inputRef.current.value = null
            }
        }
    })


    return (
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <ModalErroresCompromisos
                show={showModal}
                handleClose={() => setShowModal(false)}
                data={errorresValidacion}
            ></ModalErroresCompromisos>

            <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-semibold fs-6'>Compromisos</label>

                <div className='col-lg-10'>
                    <div className='row'>
                        <div className='col-lg-6 fv-row'>
                            <input ref={inputRef} id="fileCompromisos" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" name="fileCompromisos" type="file" onChange={(e: any) => {
                                const extension = e.currentTarget.files[0].name?.split('.').pop()

                                if (extension.toLowerCase() === 'xlsx') {
                                    formik.setFieldValue("fileCompromisos", e.currentTarget.files[0]);
                                }
                                else {
                                    formik.setFieldValue("fileIndicadores", null);
                                    e.target.value = null;
                                }
                            }} />
                            {formik.errors.fileCompromisos && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Campo obligatorio.</div>
                                </div>
                            )}
                        </div>
                        <div className='col-lg-2 fv-row'>
                            <button type='button' onClick={() => formik.handleSubmit()} className='btn btn-primary'>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    )
}

export { FormCargaCompromisos }

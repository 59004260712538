/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';
import { perfilesId } from '../../interfaces/enumeradores';
import CustomSelect from '../Helper/CustomSelect';

export function FiltroUsuario({ onBuscar, indicadores, filtrosSeleccionados }: any) {
    const filtros = useSelector((store: any) => store.appReducer.camposCreaEditaUsuario)

    return (
        <>
            <div className='row pt-5' style={{ width: '100%' }}>
                <div className='col-md-4'>
                    <label className='form-label'>
                        Centro Responsable:</label>
                    <div>
                        {filtros ?
                            <CustomSelect
                                isClearable={true}
                                value={filtrosSeleccionados.IdCentroResponsable}
                                className='fw-semibold'
                                noOptionsMessage={() => "No se encontraron resultados."}
                                placeholder={'Todos'}
                                onChange={(e: any) => onBuscar(e?.value, 'IdCentroResponsable')}
                                options={filtros?.centrosResponsables}
                            />
                            : null}
                    </div>
                </div>
                <div className='col-md-4'>
                    <label className='form-label'>
                        Rol:</label>
                    <div>
                        {filtros ?
                            <CustomSelect
                                isClearable={true}
                                className='fw-semibold'
                                noOptionsMessage={() => "No se encontraron resultados."}
                                placeholder={'Todas'}
                                onChange={(e: any) => onBuscar(e?.value, 'IdRol')}
                                value={filtrosSeleccionados.IdRol}
                                options={filtros?.perfiles}
                            />
                            : null}
                    </div>
                </div>
                <div className='col-md-4'>
                    <label className='form-label'>
                        Indicador:</label>
                    <div>
                        {filtros ?
                            <CustomSelect
                                isClearable={true}
                                value={filtrosSeleccionados.IdIndicador}
                                className='fw-semibold'
                                noOptionsMessage={() => "No se encontraron resultados."}
                                placeholder={'Todas'}
                                onChange={(e: any) => onBuscar(e?.value, 'IdIndicador')}
                                options={indicadores}
                                disabled={!(filtrosSeleccionados.IdRol == null || filtrosSeleccionados.IdRol == perfilesId.GESTOR)}

                            // disabled={!(filtrosSeleccionados.IdRol == null || filtrosSeleccionados.Idrol == perfilesId.GESTOR)}
                            />
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
}
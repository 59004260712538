import React from 'react'
import Select from 'react-select'

const CustomSelect = ({ onChange, options, value, className, placeholder, isMulti, disabled, isClearable }: any) => {
    const defaultValue = (options: any, value: any, isMulti: boolean) => {
        try {
            if (isMulti) {
                const result = options && value ? options.filter((option: any) => value.includes(option.value)) : ""
                return result
            } else {
                return options && value ? options.find((option: any) => option.value == value) : ""
            }
        }
        catch (exception) {
            console.log(exception)
        }
    };

    const deshabilitar = disabled ? disabled : false

    return (
        <div className={className}>
            <Select
                noOptionsMessage={() => "No se encontraron resultados."}
                placeholder={placeholder}
                value={defaultValue(options, value, isMulti)}
                isMulti={isMulti}
                isClearable={isClearable}
                isDisabled={deshabilitar}
                onChange={value => { onChange(value) }}
                options={options} />
        </div>

    )
}

export default CustomSelect
import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn'
import { CustomRow } from '../../modules/apps/user-management/users-list/table/columns/CustomRow'
import { User } from '../../modules/apps/user-management/users-list/core/_models'
import { KTCardBody } from '../../../_metronic/helpers'
import { columnaUsuarios } from '../ConfiguracionTabla/columnaUsuarios'
import { PaginacionUsuario } from '../ConfiguracionTabla/PaginacionUsuario'

const TablaUsuario = ({ data, getUsuarios }: any) => {
  const columns = useMemo(() => columnaUsuarios, [])

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: data?.usuarios ?? [],
  })

  const onChangePage = (numeroPagina: number) => {
    getUsuarios(numeroPagina, 'paginacion')
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-muted fw-semibold text-muted' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No se encontraron registros.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <PaginacionUsuario
        cantidadRegistros={data?.totalRegistros}
        onChangePageExt={onChangePage}
        registrosPorPagina={15}
      />
    </KTCardBody>
  )
}

export { TablaUsuario }

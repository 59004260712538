/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { descarFileOficina } from '../../services/ReporteriaService';
import { saveAs } from 'file-saver';
import { getColorCumplimiento } from '../../services/helperService';
import { Link } from 'react-router-dom'
import { getIndicadoresOficina, getOficinasRegion } from '../../services/IndicadorService';
import { FiltroOficina } from './Filtros/FiltrosOficina';
import { DefinirTabActivo } from '../../services/utilService';
import { setRegionDefecto } from '../../services/helperService'
import { indicadoresOficinaAction, setTabActivo, setUrlReturn, isLoading, setFiltrosSeleccionados, cleanIndicadorSeleccionado } from '../../redux/AppSlice'
import { toMilFormat } from '../../services/helperService'
import { ModalTipoArchivo } from './Modales/ModalTipoArchivo';

type Props = {
    className: string
}

const IndicadoresOficina = ({ className }: Props) => {
    const dispatch = useDispatch()
    const seleccionfiltros = useSelector((store: any) => store.appReducer.filtrosReporteSeleccionado)
    const usuario = useSelector((store: any) => store.appReducer.usuario)
    const filtrosReportes = useSelector((store: any) => store.appReducer.filtrosReportes)
    const indicadores = useSelector((store: any) => store.appReducer.indicadoresOficina)
    const [oficinas, setOficinas] = useState<any>(null)
    const [showFiltros, setShowFiltros] = useState<any>(null)
    const tabActivo = useSelector((store: any) => store.appReducer.tabsActivos.oficina)
    const gestorVeSoloSusCompromisos = useSelector((store: any) => store.appReducer.gestorVeSoloSusCompromisos)
    const [showModalExport, setShowModalExport] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            if (usuario && filtrosReportes && !indicadores) {
                dispatch(isLoading(true))
                dispatch(cleanIndicadorSeleccionado(null))
                try {
                    const idRegion = seleccionfiltros.idRegion || setRegionDefecto(usuario, filtrosReportes)
                    const oficinasRegion: any = await getOficinasRegion(idRegion)
                    const idficina = seleccionfiltros.idOficina || oficinasRegion[0]?.value

                    const data = await getIndicadoresOficina({
                        Agno: seleccionfiltros?.agno,
                        Mes: seleccionfiltros?.mes,
                        IdOficina: idficina,
                        IdArea: seleccionfiltros?.idArea,
                        IdCategoria: seleccionfiltros?.idCategoria,
                        gestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
                    })

                    const tab: number = DefinirTabActivo(tabActivo, data)

                    dispatch(setTabActivo({ oficina: tab ?? 0 }))
                    setOficinas(oficinasRegion)
                    dispatch(indicadoresOficinaAction(data))
                    dispatch(setFiltrosSeleccionados({ ...seleccionfiltros, idRegion: idRegion, idOficina: idficina }))
                }
                catch {
                    toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                }
                finally {
                    dispatch(isLoading(false))

                }
            }
        }
        fetchData()
    }, [className, usuario, filtrosReportes])

    const onExportar = (tipoDoc: string) => {
        dispatch(isLoading(true))

        const filtro = {
            ...seleccionfiltros,
            gestorVeSoloSusCompromisos: gestorVeSoloSusCompromisos
        }

        Promise.resolve(
            descarFileOficina(tipoDoc, filtro)
        ).then((response: any) => {
            var blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, `Nivel_Nacional.${tipoDoc}`);
            dispatch(isLoading(false))
            setShowModalExport(false)
        }).catch(reason => {
            console.log(reason)
            dispatch(isLoading(false))
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        })
    }

    return (
        <div className={`card ${className}`}>
            <ModalTipoArchivo
                show={showModalExport}
                descargarDoc={onExportar}
                handleClose={() => setShowModalExport(!showModalExport)}
            ></ModalTipoArchivo>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>
                        <div style={{ float: 'left', paddingRight: '5px' }}><img src={toAbsoluteUrl('/media/icons/duotune/art/agenda.svg')} /></div>
                        Listado de Indicadores</span>
                    <span className='sub-title fw-semibold fs-7'>Nivel Oficina</span>
                </h3>
                <div className='card-toolbar'>
                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/svg/general/calendario.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Año
                        </span>
                        <span style={{ textAlign: 'right' }} className='sub-title fw-semibold fs-7 ml-2'>{seleccionfiltros?.agno}</span>
                    </h3>
                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/svg/general/calendario.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Período
                        </span>
                        <span style={{ textAlign: 'center' }} className='sub-title fw-semibold fs-7 ml-2'>{filtrosReportes?.periodos?.filter((x: any) => x.value == seleccionfiltros?.mes)[0]?.glosa}</span>
                    </h3>

                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/icons/duotune/art/agenda.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Oficina
                        </span>
                        <span style={{ textAlign: 'right' }} className='sub-title fw-semibold fs-7 ml-2'>{oficinas?.filter((x: any) => x.value == seleccionfiltros?.idOficina)[0]?.glosa}</span>
                    </h3>

                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/icons/duotune/art/agenda.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Area
                        </span>
                        <span style={{ textAlign: 'right' }} className='sub-title fw-semibold fs-7 ml-2'>{filtrosReportes?.areas?.filter((x: any) => x.value == seleccionfiltros?.idArea)[0]?.glosa ?? 'Todas'}</span>
                    </h3>
                    <h3 className='card-title align-items-start flex-column row md-2'>
                        <span>
                            <KTSVG
                                setSvgIcon={false}
                                path='/media/icons/duotune/art/agenda.svg'
                                className='svg-icon-3'
                            />
                            {'   '} Compromiso
                        </span>
                        <span style={{ textAlign: 'center' }} className='sub-title fw-semibold fs-7 ml-2'>{filtrosReportes?.compromisos?.filter((x: any) => x.value == seleccionfiltros?.idCategoria)[0]?.glosa ?? 'Todas'}</span>
                    </h3>
                    <h3 className='card-title align-items-center flex-column'>
                        <button className='dot'
                            onClick={() => setShowFiltros(!showFiltros)}
                        >
                            <KTSVG
                                setSvgIcon={false}
                                path={showFiltros ? '/media/svg/general/close.svg' : '/media/svg/general/filtro.svg'}
                                className='svg-icon-3'
                            />
                        </button>
                        <span className='text-muted fw-semibold fs-7 ml-2'>{showFiltros ? 'Desactivar filtros' : 'Activar filtros'}</span>
                    </h3>
                </div>
            </div>
            <div className='card-header border-0' style={{ minHeight: '10px' }}>
                <h3 className='align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Información acumulada a {filtrosReportes?.periodos?.filter((x: any) => x.value == seleccionfiltros?.mes)[0]?.glosa} del {seleccionfiltros?.agno} </span>
                </h3>
            </div>
            <div className='row'><div className='col-md-10 offset-md-1'><hr></hr></div></div>
            <div className='card-body pt-0 pb-0'>
                {showFiltros ?
                    <FiltroOficina
                        setOficinas={setOficinas}
                        oficinas={oficinas}
                    ></FiltroOficina>
                    : null}
            </div>

            <div className='card-body pt-0 pb-0'>
                <div className='d-flex overflow-auto'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        {indicadores?.map((item: any, index: number) => (
                            <li className='nav-item' key={index} onClick={() => dispatch(setTabActivo({ oficina: item.idCategoria }))}>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (tabActivo == item.idCategoria && 'active')
                                    }
                                >     {item.nombreCategoria}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='card-body py-3 pb-0 pt-0'>
                <div className='table-responsive'>
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-semibold text-muted'>
                                <th className='min-w-150px'>Compromiso</th>
                                <th className='min-w-140px'>Numerador</th>
                                <th className='min-w-120px'>Denominador</th>
                                <th className='min-w-120px'>Valor</th>
                                <th className='min-w-120px'>Meta</th>
                                <th className='min-w-120px'>% Cumpto</th>
                                <th className='min-w-100px'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {indicadores?.filter((x: any) => x.idCategoria == tabActivo)?.map((itemCat: any, indexCat: number) => (
                                <React.Fragment key={`${indexCat}-cat`}>
                                    {itemCat?.indicadores?.map((item: any, index: number) => (
                                        <tr key={`${index}-ind`}>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {item.compromiso}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {toMilFormat(item.numerador)}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {toMilFormat(item.denominador)}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {toMilFormat(item.valor)}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {toMilFormat(item.metaNacional)}
                                                </span>
                                            </td>
                                            <td className='text-end'>
                                                <div className='d-flex flex-column w-100 me-2'>
                                                    <div className='d-flex flex-stack mb-2'>
                                                        <span className='me-2 fs-7 fw-semibold'>{toMilFormat(item.porcentajeCumplimiento)}%</span>
                                                    </div>
                                                    <div className='progress h-6px w-100'>
                                                        <div
                                                            className={`progress-bar ${getColorCumplimiento(item.porcentajeCumplimiento)}`}
                                                            role='progressbar'
                                                            style={{ width: `${item.porcentajeCumplimiento.toString()}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='d-flex justify-content-start flex-shrink-0'>
                                                    <Link
                                                        onClick={() => dispatch(setUrlReturn('/oficina'))}
                                                        title='Ver detalle'
                                                        className='btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        to={`/indicador/${item.idIndicador}/${seleccionfiltros?.idOficina}/oficina`}
                                                    >
                                                        <KTSVG
                                                            setSvgIcon={false}
                                                            path='/media/icons/duotune/general/gen063.svg'
                                                            className='svg-icon-custom'
                                                        />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {indicadores ?
                <div className='card-header border-0 pt-0 pb-0'>
                    <div className='card-title'></div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <button type='button' onClick={() => setShowModalExport(true)} className='btn btn-sm btn-primary'>
                                <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                Exportar
                            </button>
                        </div>
                    </div>
                </div> : null}
        </div>
    )
}

export default IndicadoresOficina

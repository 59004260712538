export const ValidarDecimalGrilla = (newValue) => {
    if (isNaN(newValue) || newValue === '') {
        return {
            valid: false,
            message: 'Número inválido.'
        }
    }
    else {
        const decimalPlaces = newValue.split('.')[1]?.length ?? 0

        if (decimalPlaces > 2) {
            return {
                valid: false,
                message: 'Sólo debe poseer 2 decimales.'
            }
        }
        else {
            return {
                valid: true
            }
        }

    }
}

export const toMilFormat = (numero) => {
    if (numero) {

        let stringNumero = numero.toString().replace('.', ',')

        return stringNumero.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return numero
}

export const blockInvalidCharNumber = e => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

export const setRegionDefecto = (usuario, filtros) => {
    const region = filtros?.regiones.filter(x => x.value == usuario?.idCentroResponsable)[0]?.value

    return region || filtros?.regiones[0]?.value
}

export const setCentroNacionalDefecto = (usuario, centrosNacionales, idCentroSeleccionado) => {
    if(idCentroSeleccionado){
        return idCentroSeleccionado
    }
    else if (usuario && centrosNacionales) {
        const centro = centrosNacionales?.filter(x => x.value == usuario?.idCentroResponsable)[0]?.value
        return centro || ''
    }
    else {
        return ''
    }
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const toNombreMes = (mes) => {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    if (mes) {
        return meses[mes - 1]
    }

    return ''
}

export const calcularAlturaGrafico = (cantidadResultado, alturaPorColumna) => {
    let alturaMinima = 150//altura minima
    let altura = 0

    if (cantidadResultado > 0) {
        altura = cantidadResultado * alturaPorColumna
    }

    const resultado = altura > alturaMinima ? (altura + 'px') : (alturaMinima + 'px')

    return resultado
}

export const CortarTextoGrafico = (largoMaximo, texto) => {
    if (texto) {

        const largoTexto = texto?.length

        if (largoTexto <= largoMaximo) {
            return texto
        }
        else {
            let nuevoTexto = texto.substring(0, largoMaximo - 3)
            nuevoTexto = nuevoTexto + '...'

            return nuevoTexto
        }
    }

    return texto
}

export const getColorCumplimiento = (porcentajeCumplimiento) => {
    if (porcentajeCumplimiento) {
        if (porcentajeCumplimiento > 100) {
            return 'bg-success-bold'
        }
        else if (porcentajeCumplimiento >= 90) {
            return 'bg-success'
        }
        else if (porcentajeCumplimiento < 90 && porcentajeCumplimiento >= 75) {
            return 'bg-warning'
        }
        else if (porcentajeCumplimiento < 75) {
            return 'bg-danger'
        }
    }

    return ''
}

export const getFlechaCumplimiento = (porcentajeCumplimiento) => {
    if (porcentajeCumplimiento) {
        if (porcentajeCumplimiento >= 90) {
            return { path: '/media/icons/duotune/arrows/arr066.svg', color: 'success' }
        }
        else if (porcentajeCumplimiento < 90 && porcentajeCumplimiento >= 75) {
            return { path: '/media/icons/duotune/general/gen024.svg', color: 'warning' }
        }
        else if (porcentajeCumplimiento < 75) {
            return { path: '/media/icons/duotune/arrows/arr065.svg', color: 'danger' }
        }
    }

    return ''
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { getColorCumplimiento } from '../../../services/helperService';
import { KTSVG } from '../../../_metronic/helpers'
import { setUrlReturn } from '../../../redux/AppSlice';
import { toMilFormat } from '../../../services/helperService'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom'

const IndicadoresNacionalTabla = ({ idCategoria }: any) => {
    const indicadores = useSelector((store: any) => store.appReducer.indicadoresNacionales)
    const dispatch = useDispatch()

    const retornarEstado = (ultimoComentario: string) => {
        switch (ultimoComentario) {
            case 'Validado por Administrador':
                return <div className='badge bg-success-bold fw-bolder'>{ultimoComentario}</div>
            case 'Validado por Jefe de Centro':
                return <div className='badge badge-light-success fw-bolder'>{ultimoComentario}</div>
            default:
                return <div className='badge badge-light-danger fw-bolder'>{ultimoComentario}</div>
        }
    }


    return (
        <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-semibold text-muted'>
                        <th style={{ width: '285px' }} className='min-w-100px'>Compromiso</th>
                        <th className='min-w-140px'>Numerador</th>
                        <th className='min-w-120px'>Denominador</th>
                        <th className='min-w-120px'>Valor</th>
                        <th className='min-w-120px'>Meta</th>
                        {/* meta parcial solo se muestra en indicadores de monitoreo interno" */}
                        {idCategoria === 6 && (<th className='min-w-120px'>% Cumpto Parcial</th>)}
                        <th className='min-w-120px'>% Cumpto</th>
                        <th className='min-w-120px'>Estado</th>
                        <th className='min-w-100px'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {indicadores?.filter((x: any) => x.idCategoria == idCategoria)?.map((itemCat: any, indexCat: number) => (
                        <React.Fragment key={`${indexCat}-cat`}>
                            {itemCat?.indicadores?.map((item: any, index: number) => (
                                <tr key={`${index}-ind`}>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {item.compromiso}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {toMilFormat(item.numerador)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {toMilFormat(item.denominador)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {toMilFormat(item.valor)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            {toMilFormat(item.metaNacional)}
                                        </span>
                                    </td>
                                    {/* --------------------------------------------------- */}
                                    {idCategoria === 6 ?
                                        <td className='text-end'>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    {item.porcentajeCumplimientoParcial && (<span className='text-muted me-2 fs-7 fw-semibold'>{toMilFormat(item.porcentajeCumplimientoParcial)}%</span>)}
                                                </div>
                                                {item.porcentajeCumplimientoParcial && (
                                                    <div className='progress h-6px w-100'>

                                                        <div

                                                            className={`progress-bar ${getColorCumplimiento(item.porcentajeCumplimientoParcial)}`}
                                                            role='progressbar'
                                                            style={{ width: `${item.porcentajeCumplimientoParcial.toString()}%` }}

                                                        ></div>

                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        : null}
                                    {/* --------------------------------------------------- */}
                                    <td className='text-end'>
                                        <div className='d-flex flex-column w-100 me-2'>
                                            <div className='d-flex flex-stack mb-2'>
                                                <span className='text-muted me-2 fs-7 fw-semibold'>{toMilFormat(item.porcentajeCumplimiento)}%</span>
                                            </div>
                                            <div className='progress h-6px w-100'>
                                                <div

                                                    className={`progress-bar ${getColorCumplimiento(item.porcentajeCumplimiento)}`}
                                                    role='progressbar'
                                                    style={{ width: `${item.porcentajeCumplimiento.toString()}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {retornarEstado(item.ultimoComentario)}
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-start flex-shrink-0'>
                                            <Link
                                                onClick={() => dispatch(setUrlReturn('/dashboard'))}
                                                title='Ver detalle'
                                                className='btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                to={`/indicador/${item.idIndicador}/0/Nacional`}
                                            >
                                                <KTSVG
                                                    setSvgIcon={false}
                                                    path='/media/icons/duotune/general/gen063.svg'
                                                    className='svg-icon-3'
                                                />
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table >
        </div >
    )
}

export default IndicadoresNacionalTabla
import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const getUsuarios = async (filtros) => {
    return axios.post(`${urlApi}/api/AdminUsuario/Usuarios`, filtros)
        .then((res) => { return res.data })
}

export const existeUsuarioBD = async (mailUsuario) => {
    return axios.get(`${urlApi}/api/AdminUsuario/Existe/${mailUsuario}`)
        .then((res) => { return res.data })
}

export const crearUsuarioEnBd = async (values) => {
    return axios.post(`${urlApi}/api/AdminUsuario/Crear`, values)
        .then((res) => { return res.data })
}

export const getDetalleUsuario = async (idUsuario) => {
    return axios.get(`${urlApi}/api/AdminUsuario/DetalleUsuario/${idUsuario}`)
        .then((res) => { return res.data })
}

export const UpdateUsuario = async (values) => {
    return axios.post(`${urlApi}/api/AdminUsuario/Update`, values)
        .then((res) => { return res.data })
}

export const HabilitarInhabilitarUsuario = async (values) => {
    return axios.post(`${urlApi}/api/AdminUsuario/Habilitar`, values)
        .then((res) => { return res.data })
}

export const ValidarAsignacionIndicador = async (data) => {
    return axios.post(`${urlApi}/api/Util/ValidaAsignacionIndicadores`, data)
        .then((res) => { return res.data })
}
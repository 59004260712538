import React, { useState, useEffect } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { CrearUsuarioModel, crearUsuarioInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import { useFormik } from 'formik'
import { getDatosUsuarioAd, getFotoUsuarioACrear } from '../../../services/graphService'
import { toast } from "react-toastify"
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch } from 'react-redux'
import { toBase64 } from '../../../services/helperService'
import { getCamposCreacionUsuario } from '../../../services/utilService'
import CustomSelect from '../Helper/CustomSelect'
import { perfilesId } from '../../interfaces/enumeradores'
import { existeUsuarioBD, crearUsuarioEnBd, ValidarAsignacionIndicador } from '../../../services/AdminUsuarioService'
import { getIndicadoresCentroResponsable } from '../../../services/utilService'

const CrearUsuario: React.FC = () => {
  const dispatch = useDispatch()
  const [dataFiltros, setFiltros] = useState<any>(null)
  const [indicadores, setIndicadores] = useState<any>(null)
  const [usuarioAd, setUsuarioAd] = useState<any>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filtros = await getCamposCreacionUsuario()
        setFiltros(filtros)
      }
      catch {

      }
    }
    fetchData()
  }, [])

  const validate = async (values: CrearUsuarioModel) => {
    const errors: any = {}

    if (values.email === '') {
      errors.email = 'Email es requerido'
    }

    if (values.nombre === '') {
      errors.nombre = 'Nombre es requerido'
    }

    if (values.perfil === '') {
      errors.perfil = 'Perfil es requerido'
    }

    if (!values.centroResponsable && values.perfil != perfilesId.ADMINISTRADOR.toString()) {
      errors.centroResponsable = 'Centro Responsable es requerido'
    }

    if (values.perfil == perfilesId.GESTOR.toString() && values.centroResponsable) {
      if (dataFiltros.centrosResponsables.some((x: any) => x.value == values.centroResponsable && !x.tieneGestor)) {
        errors.centroResponsable = 'Centro responsable no posee gestor.'
      }
      else {
        const resultado = await ValidarAsignacionIndicador({
          IdCentroResponsable: values.centroResponsable,
          IdIndicador: values.indicadores,
          IdUsuario: 0,
          IdRol: parseInt(values.perfil)
        })
        if (resultado != '') {
          errors.indicadores = resultado
        }
      }
    }

    if (values.perfil == perfilesId.SUPERVISOR.toString() && values.centroResponsable !== '') {
      if (dataFiltros.centrosResponsables.some((x: any) => x.value == values.centroResponsable && !x.tieneSupervisor)) {
        errors.centroResponsable = 'Centro responsable no posee supervisor.'
      }
      else {
        const resultado = await ValidarAsignacionIndicador({
          IdCentroResponsable: values.centroResponsable,
          IdIndicador: values.indicadores,
          IdUsuario: 0,
          IdRol: parseInt(values.perfil)
        })
        if (resultado != '') {
          errors.centroResponsable = resultado
        }
      }
    }

    return errors;
  }

  const formik = useFormik<CrearUsuarioModel>({
    initialValues,
    validate,
    // validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      try {
        dispatch(isLoading(true))
        const resultado: boolean = await crearUsuarioEnBd(values)

        if (resultado) {
          formik.resetForm()
          setUsuarioAd(null)
          toast.success('Usuario creado correctamente.')
        }
        else {
          toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }
      }
      catch {
        toast.error('Ha ocurrido un error, inténtelo nuevamente.')
      }
      finally {
        dispatch(isLoading(false))
      }
    },
  })

  const getUsuarioAd = async () => {
    if (formik.touched.email && !formik.errors.email) {
      dispatch(isLoading(true))
      let usuario = null
      let fotoBase64 = null
      let existeBd: boolean = true

      try {
        existeBd = await existeUsuarioBD(formik.values.email)

        if (!existeBd) {
          const data = await getDatosUsuarioAd(formik.values.email)

          if (data.value.length > 0) {
            usuario = data.value[0]
            formik.setFieldValue('nombre', usuario.displayName)
          }
        }
      }
      catch {
        toast.error('Ha ocurrido un error, inténtelo nuevamente.')
      }

      if (usuario) {
        try {
          const response = await getFotoUsuarioACrear(usuario.id)
          var blob = new Blob([response.data], { type: response.headers["content-type"] });
          fotoBase64 = await toBase64(blob)
        }
        catch (ex: any) {
        }
      }
      else if (!existeBd && !usuario) {
        toast.info('El email ingresado no existe.')
      }
      else if (existeBd) {
        toast.info('El email ingresado ya se encuentra registrado.')
      }

      setUsuarioAd({
        existe: usuario != null,
        foto: fotoBase64
      })

      dispatch(isLoading(false))
    }
    else if (!formik.touched.email) {
      formik.setFieldTouched('email', true)
    }
  }

  const onCancelar = () => {
    formik.resetForm()
    setUsuarioAd(null)
  }

  const onChangeCustomSelect = async (value: any, isMulti: boolean, inputName: string) => {
    if (inputName == 'centroResponsable') {
      const data = await getIndicadoresCentroResponsable(value.value)
      setIndicadores(data)
    }

    if (inputName == 'centroResponsable') {
      formik.setFieldValue('indicadores', null)
    }

    if (inputName == 'perfil' && value.value != perfilesId.GESTOR) {
      formik.setFieldValue('indicadores', null)
    }

    if (inputName == 'perfil' && value.value == perfilesId.ADMINISTRADOR) {
      formik.setFieldValue('centroResponsable', null)
    }

    if (isMulti) {
      const values = value.map((item: any) => item.value)

      formik.setFieldValue(inputName, values)
    }
    else {
      formik.setFieldValue(inputName, value.value)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Usuario</h3>
        </div>
      </div>


      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Email</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      readOnly={usuarioAd?.existe}
                      className='form-control form-control-lg mb-3 mb-lg-0'
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <button type='button' onClick={() => getUsuarioAd()} className='btn btn-primary' disabled={usuarioAd?.existe}>
                      Validar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {usuarioAd?.existe ?
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                      style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                    >
                      {usuarioAd?.foto ?
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{ backgroundImage: `url(${usuarioAd.foto}` }}
                        ></div>
                        :
                        <div
                          className='image-input-wrapper w-125px h-125px'
                          style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                        ></div>}
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label required fw-semibold fs-6'>Nombre</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg mb-3 mb-lg-0'
                          placeholder='nombre'
                          {...formik.getFieldProps('nombre')}
                        />
                        {formik.touched.nombre && formik.errors.nombre && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.nombre}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>
                    <span className='required'>Perfil</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <CustomSelect
                      className='fw-semibold'
                      value={formik.values.perfil}
                      noOptionsMessage={() => "No se encontraron resultados."}
                      placeholder={'Seleccione perfil...'}
                      onChange={(value: any) => onChangeCustomSelect(value, false, 'perfil')}
                      options={dataFiltros?.perfiles}
                    />
                    {formik.touched.perfil && formik.errors.perfil && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.perfil}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>
                    <span className='required' >Centro responsable</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <CustomSelect
                      onChange={(value: any) => onChangeCustomSelect(value, false, 'centroResponsable')}
                      options={dataFiltros?.centrosResponsables}
                      value={formik.values.centroResponsable}
                      className='fw-semibold'
                      placeholder={'Seleccione centro responsable...'}
                      isMulti={false}
                      disabled={formik.values.perfil == perfilesId.ADMINISTRADOR.toString()}
                    />
                    {formik.touched.centroResponsable && formik.errors.centroResponsable && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.centroResponsable}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label fw-semibold fs-6'>
                    <span className=''>Indicadores</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <CustomSelect
                      className='fw-semibold'
                      isMulti={true}
                      noOptionsMessage={() => "No se encontraron resultados."}
                      placeholder={'Seleccione Indicadores...'}
                      onChange={(value: any) => onChangeCustomSelect(value, true, 'indicadores')}
                      disabled={formik.values.perfil != perfilesId.GESTOR.toString()}
                      options={indicadores}
                      value={formik.values.indicadores}
                    />
                    {formik.errors.indicadores && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.indicadores}</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
              : null}
          </form>
        </div>

        {usuarioAd?.existe ?
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button onClick={() => onCancelar()} style={{ marginRight: '10px' }} className='btn btn-sm btn-light'>Cancelar</button>
            <button type='button' onClick={() => formik.handleSubmit()} className='btn btn-primary'>
              Guardar
            </button>
          </div> : null}

      </div>
    </div>
  )
}

export { CrearUsuario }

import { KTCard } from '../../../_metronic/helpers'
import { TablaUsuario } from '../../components/tablas/TablaUsuarios'
import { HeaderListaUsuario } from '../../components/Administracion/HeaderListaUsuario'
import { useState, useEffect } from 'react'
import { perfilesId } from '../../interfaces/enumeradores'
import { descarFileUsuarios } from '../../../services/ReporteriaService'
import { getUsuarios } from '../../../services/AdminUsuarioService'
import { toast } from 'react-toastify'
import { isLoading, setCamposCreacionEdicionUsuario } from '../../../redux/AppSlice'
import { getCamposCreacionUsuario } from '../../../services/utilService'
import { useDispatch } from 'react-redux'
import { ModalTipoArchivo } from '../../components/Modales/ModalTipoArchivo'
import { saveAs } from 'file-saver'

const ListadoUsuario = () => {
    const dispatch = useDispatch()
    const [data, setUsuarios] = useState<any>([])
    const [filtros, setFiltros] = useState<any>({ nombreOMail: null, IdCentroResponsable: null, IdRol: null, IdIndicador: null })
    const registrosPorPagina = 15
    const [showModalExport, setShowModalExport] = useState<boolean>(false)

    useEffect(() => {
        fetchData(1)
    }, [])

    const onFiltrarUsuarios = async (value: string, origen: string) => {
        let filtro = null

        if (origen != 'paginacion') {
            filtro = {
                ...filtros,
                [origen]: origen == 'nombreOMail' ? value : value ? parseInt(value) : null,
                numeroPagina: 1,
                cantidadRegistros: registrosPorPagina,
            }

            //si cambia de rol se limpian indicadores
            if (origen === 'IdRol') {
                if (parseInt(value) !== perfilesId.GESTOR && value) {
                    filtro = {
                        ...filtro,
                        IdIndicador: null
                    }
                }
            }

            setFiltros(filtro)
        }
        else {
            filtro = {
                ...filtros,
                numeroPagina: parseInt(value),
                cantidadRegistros: registrosPorPagina,
            }
        }

        const usuarios = await getUsuarios(filtro)

        setUsuarios(usuarios)
    }

    const fetchData = async (numeroPagina: number) => {
        dispatch(isLoading(true))

        Promise.all([getUsuarios({ numeroPagina, CantidadRegistros: registrosPorPagina }), getCamposCreacionUsuario()]).then(values => {
            setUsuarios(values[0])
            dispatch(setCamposCreacionEdicionUsuario(values[1]))
        }).catch(reason => {
            console.log(reason)
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        }).finally(() => {
            dispatch(isLoading(false))
        });
    }

    const onExportar = (tipoDoc: string) => {
        dispatch(isLoading(true))

        Promise.resolve(
            descarFileUsuarios(tipoDoc, filtros)
        ).then((response: any) => {
            var blob = new Blob([response.data], { type: response.headers["content-type"] });
            saveAs(blob, `Usuarios.${tipoDoc}`);
            dispatch(isLoading(false))
            setShowModalExport(false)
        }).catch(reason => {
            console.log(reason)
            dispatch(isLoading(false))
            toast.error('Ha ocurrido un error, inténtelo nuevamente.')
        })
    }

    return (
        <>
            <KTCard>
                <ModalTipoArchivo
                    show={showModalExport}
                    descargarDoc={onExportar}
                    handleClose={() => setShowModalExport(!showModalExport)}
                ></ModalTipoArchivo>
                <HeaderListaUsuario onExporta={() => setShowModalExport(true)} filtrosSeleccionados={filtros} getUsuarios={onFiltrarUsuarios} />
                <TablaUsuario
                    data={data}
                    getUsuarios={onFiltrarUsuarios} />
            </KTCard>
        </>
    )
}

const ListadoUsuarioWrapper = () => (
    <ListadoUsuario />
)

export { ListadoUsuarioWrapper }

import { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik'
import { EditarPeriodoModel, editarPeriodoInitValues as initialValues } from '../../modules/accounts/components/settings/SettingsModel'
import dayjs from "dayjs"
import { UpdatePeriodo } from '../../../services/AdminPeriodo'

type Props = {
    show: boolean
    handleClose: () => void,
    periodoSeleccionado: any,
    updateData: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalEditarPeriodo = ({ show, handleClose, periodoSeleccionado, updateData }: Props) => {
    const dispatch = useDispatch()

    const validate = async (values: EditarPeriodoModel) => {
        const errors: any = {}

        return errors;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (show && periodoSeleccionado) {
                    formik.setValues({
                        FechaInicio: dayjs(periodoSeleccionado.fechaInicio).format('YYYY-MM-DD'),
                        FechaFin: dayjs(periodoSeleccionado.fechaFin).format('YYYY-MM-DD'),
                        id: periodoSeleccionado.id,
                        Estado: periodoSeleccionado.estado
                    }, false)
                }
            }
            catch (error) {
                console.log(error)
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        }
        fetchData()
    }, [periodoSeleccionado, show])


    const formik = useFormik<EditarPeriodoModel>({
        initialValues,
        validate,
        onSubmit: async (values) => {
            try {
                dispatch(isLoading(true))
                const resultado: boolean = await UpdatePeriodo(values)

                if (resultado) {
                    onCancelar()
                    formik.resetForm()
                    toast.success('Período actualizado correctamente.')
                }
                else {
                    toast.error('Ha ocurrido un error, inténtelo nuevamente.')
                }
            }
            catch {
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        },
    })

    const onCancelar = () => {
        updateData()
        handleClose()
        formik.resetForm()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
        >
            {/* <div className='modal-header'>
<div></div>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div> */}
            <div className='modal-body scroll-y mx-xl-5'>
                <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div
                        className='d-flex flex-column scroll-y me-n7 pe-7'
                        id='kt_modal_add_user_scroll'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_modal_add_user_header'
                        data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                        data-kt-scroll-offset='300px'
                    >
                        <div className='row  d-flex justify-content-center mb-4 mt-4'>
                            <div className='col-md-12'>
                                <a href='#' className='text-gray-700 text-hover-primary fs-2 me-1'>
                                    Período: {dayjs(periodoSeleccionado?.periodoProceso).format('MM/YYYY')}
                                </a>
                            </div>
                        </div>
                        <div className='row mb-3 d-flex justify-content-center'>
                            <div className='col-md-12'>
                                <label className='required fw-bold fs-6 mb-2'>Nuevo Estado</label>
                                <select
                                    className='form-select form-select-solid'
                                    data-kt-select2='true'
                                    data-placeholder='Select option'
                                    data-allow-clear='true'
                                    {...formik.getFieldProps('Estado')}
                                    defaultValue={periodoSeleccionado?.estado}
                                >
                                    <option value='Abierto'>Abierto</option>
                                    <option value='Cerrado'>Cerrado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='text-center pt-15'>
                        <button
                            type='reset'
                            onClick={() => onCancelar()}
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'
                            disabled={formik.isSubmitting}
                        >
                            Cancelar
                        </button>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                        >
                            <span className='indicator-label'>Guardar</span>
                        </button>
                    </div>
                </form>
            </div >
        </Modal >,
        modalsRoot
    )
}

export { ModalEditarPeriodo }

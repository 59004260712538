import { useRef } from 'react'
import { cargaInitValues as initialValues, IFormCarga } from '../../modules/accounts/components/settings/SettingsModel'
import { useFormik, FormikErrors } from 'formik'
import { saveNuevoIndicadores } from '../../../services/AdminIndicador'
import { toast } from "react-toastify"
import { isLoading } from '../../../redux/AppSlice'
import { saveAs } from 'file-saver'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const FormCargaIndicadores: React.FC = () => {
    const dispatch = useDispatch()

    const validate = (values: IFormCarga) => {
        let errors: FormikErrors<IFormCarga> = {};

        if (values.fileIndicadores === null) {
            errors.fileIndicadores = 'Campo obligatorio.'
        }

        return errors;
    }

    const formik = useFormik<IFormCarga>({
        initialValues,
        validate,
        onSubmit: (values) => {
            dispatch(isLoading(true))
            const formData = new FormData()
            formData.append("indicadores", values.fileIndicadores)

            Promise.resolve(
                saveNuevoIndicadores(formData)
            ).then((response: any) => {
                var blob = new Blob([response.data], { type: response.headers["content-type"] });
                saveAs(blob, `resultado.xlsx`);
                dispatch(isLoading(false))
            }).catch(reason => {
                console.log(reason)
                dispatch(isLoading(false))
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }).finally(() => {
                formik.setFieldValue('fileIndicadores', null, false)

                if (inputRef?.current)
                    inputRef.current.value = null
            })
        }
    })

    const inputRef = useRef<any>(null)

    return (
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
            <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-semibold fs-6'>Indicadores</label>

                <div className='col-lg-10'>
                    <div className='row'>
                        <div className='col-lg-6 fv-row'>
                            <input
                                ref={inputRef}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                name="fileIndicadores"
                                type="file"
                                onChange={(e: any) => {
                                    const extension = e.currentTarget.files[0].name?.split('.').pop()

                                    if (extension.toLowerCase() === 'xlsx') {
                                        formik.setFieldValue("fileIndicadores", e.currentTarget.files[0]);
                                    }
                                    else {
                                        formik.setFieldValue("fileIndicadores", null);
                                        e.target.value = null;
                                    }
                                }}
                            />
                            {formik.errors.fileIndicadores && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>Campo obligatorio.</div>
                                </div>
                            )}
                        </div>
                        <div className='col-lg-2 fv-row'>
                            <button type='button' onClick={() => formik.handleSubmit()} className='btn btn-primary'>
                                Guardar
                            </button>
                        </div>
                        <div className='col-lg-3 fv-row'>
                            <a href={toAbsoluteUrl('/media/files/CargaIndicadores.xlsx')} className='btn btn-primary'>
                                Archivo de Carga
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export { FormCargaIndicadores }

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect } from 'react'
import { useState } from 'react'

const PaginacionUsuario = ({ cantidadRegistros, onChangePageExt, registrosPorPagina }: any) => {
    const [paginaActual, setPaginaActual] = useState(1)
    const [cantidadPaginas, setCantidadPaginas] = useState(0)
    const [pages, setPages] = useState<any>([])

    useEffect(() => {
        setPages(getPages())
    }, [cantidadRegistros, paginaActual, registrosPorPagina])

    useEffect(() => {
        setCantidadPaginas(Math.ceil(cantidadRegistros / registrosPorPagina))
    }, [cantidadRegistros, registrosPorPagina])

    const onChangePage = (numeroPagina: number) => {
        setPaginaActual(numeroPagina)
        onChangePageExt(numeroPagina)
    }

    const getPages = () => {
        var currentPage = paginaActual
        var totalPages = Math.ceil(cantidadRegistros / registrosPorPagina)

        var startPage: number, endPage: number;

        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        return pages
    }

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        <li
                            className={clsx('page-item previus')}
                        >
                            <a
                                className={clsx('page-link page-text me-5', {
                                    disabled: paginaActual === 1
                                })}
                                onClick={() => onChangePage(paginaActual - 1)}
                                style={{ cursor: 'pointer' }}
                            >
                                Anterior
                            </a>
                        </li>
                        {pages.map((page: number, i: number) =>
                            <li
                                key={page}
                                className={clsx('page-item', {
                                    active: paginaActual === page,
                                    disabled: false
                                })}
                            >
                                <a
                                    className={clsx('page-link')}
                                    onClick={() => onChangePage(page)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {page}
                                </a>
                            </li>
                        )}
                        <li
                            className={clsx('page-item next')}
                        >
                            <a
                                className={clsx('page-link page-text me-5', {
                                    disabled: paginaActual === cantidadPaginas
                                })}
                                onClick={() => onChangePage(paginaActual + 1)}
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                            >
                                Siguiente
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { PaginacionUsuario }
import React from 'react'
import { FormCargaCompromisos } from './FormCargaCompromiso'
import { FormCargaIndicadores } from './FormCargaIndicadores'

const CrearIndicador: React.FC = () => {
    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Cargar Indicadores</h3>
                    </div>
                </div>
                <div className='modal-body mx-5 mx-xl-9 my-1 '>
                    <FormCargaIndicadores></FormCargaIndicadores>

                    <div className='col-sm-11 col-xs-12 mb-4 mt-4'>
                        <div className='textoInformativo' style={{ maxWidth: '100%' }}>
                            <span style={{ textAlign: 'justify' }}>
                                <strong>- Paso 1:</strong> Carga de Indicadores, debe descargar archivo de ejemplo para ser rellenado.
                                Una vez rellenado y cargado el archivo, se aplicarán validaciones de forma al documento, de ser
                                correcto se decargará un nuevo archivo para cargar los compromisos. En caso contrario, se descargará
                                un archivo excel indicando los errores encontrados.
                            </span>
                            <br></br>
                            <span style={{ textAlign: 'justify' }}>
                                <strong>- NOTA:</strong> En caso que el indicador pertenezca a más de una categoría, estas deben ser separadas por ";"
                                por ejemplo 1;2.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Cargar Compromisos de Indicadores</h3>
                    </div>
                </div>
                <div className='modal-body mx-5 mx-xl-9 my-1 mt-5'>
                    <FormCargaCompromisos></FormCargaCompromisos>

                    <div className='col-sm-11 col-xs-12 mb-4 mt-4'>
                        <div className='textoInformativo' style={{ maxWidth: '100%' }}>
                            <span style={{ textAlign: 'justify' }}>
                                <strong>- Paso 2:</strong> Carga de Compromisos, acá debe cargar el archivo obtenido en el paso 1 una vez que se haya
                                rellenado con los compromisos asociados a los indicadores cargados.
                                En este archivo, en cada fila debe rellenar el centro responsable e indicador al cual corresponde el compromiso.
                            </span>
                            <br></br>
                            <span style={{ textAlign: 'justify' }}>
                                <strong>- NOTA:</strong> En caso que el indicador pertenezca a más de una categoría, En la columna de ponderados, estos valores
                                deben ser separados por ";".
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { CrearIndicador }

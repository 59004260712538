/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { PageTitle } from '../../../_metronic/layout/core'
import { codigoPerfiles } from '../../interfaces/enumeradores'
import { Navigate } from 'react-router-dom'
import Loader from '../../components/Helper/Loader'
import { CrearUsuario } from '../../components/Administracion/CrearUsuario'

const CrearUsuarioPage: FC = () => (
    <>
        <div className='row gy-5 gx-xl-8'>
            <div className='col-xl-12'>
                <CrearUsuario></CrearUsuario>
            </div>
        </div>
    </>
)

const CrearUsuarioWrapper: FC = () => {
    const intl = useIntl()

    return (
        <>
            <React.Fragment>
                <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Administración' })}</PageTitle>
                <CrearUsuarioPage />
            </React.Fragment> :

        </>
    )
}

export { CrearUsuarioWrapper }

import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Helper/Loader'

const Puente = () => {
    const usuario = useSelector((store: any) => store.appReducer.usuario)
    const navigate = useNavigate()

    useEffect(() => {
        if (usuario) {
            navigate(usuario.paginaRedireccion)
            // window.location.href = usuario.paginaRedireccion;
        }
    }, [usuario])

    return (
        <Loader></Loader>
    )
}


export default Puente

import axiosConfig from '../config/axiosBackend'
const urlApi = process.env.REACT_APP_API_INDICADORES
const axios = axiosConfig.iAxios

export const getRegionesIndicador = async (idIndicador, periodo) => {
    return axios.get(`${urlApi}/api/util/regiones/${idIndicador}/${periodo ?? ''}`)
        .then((res) => { return res.data })
}

export const getEvolucion = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/Evolucion`, filtros)
        .then((res) => { return res.data })
}

export const getDetalleCumplimiento = async (filtros) => {
    return axios.post(`${urlApi}/api/reporte/DetalleCumplimiento`, filtros)
        .then((res) => { return res.data })
}

export const getDetalleIndicadorPeriodo = async (idIndicador, periodo, idRegion, idOficina) => {
    return axios.get(`${urlApi}/api/indicador/DetalleIndicadorPeriodo/${idIndicador}/${periodo}/${idRegion}/${idOficina}`)
        .then((res) => { return res.data })
}

export const getReferenciasPaginado = async (data) => {
    return axios.post(`${urlApi}/api/reporte/PaginadoDetalleCumplimiento`, data)
        .then((res) => { return res.data })
}

export const getFiltrosReportes = async () => {
    return axios.get(`${urlApi}/api/Util/filtros`).then((res) => { return res.data })
}

export const getOficinasRegion = async (idRegion) => {
    return axios.get(`${urlApi}/api/Util/oficinas/${idRegion}`).then((res) => { return res.data })
}

export const getActualizacionFiltrosReportes = async (agno, mes) => {
    return axios.get(`${urlApi}/api/Util/actualizaFiltros/${agno}/${mes}`).then((res) => { return res.data })
}

export const getDetalleIndicadorPorOficina = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/oficinaIndicador`, filtros).then((res) => { return res.data })
}

export const getCompromisoIndicadorUsuario = async (agno, idIndicador) => {
    return axios.get(`${urlApi}/api/Usuario/compromiso/${idIndicador}/${agno}`)
        .then((res) => { return res.data })
}

export const getComentariosIndicadorPeriodo = async (data) => {
    return axios.post(`${urlApi}/api/indicador/AnalisisIndicador`, data)
        .then((res) => { return res.data })
}

export const getCentrosIndicador = async (idIndicador, agno) => {
    return axios.get(`${urlApi}/api/indicador/centrosIndicador/${idIndicador}/${agno}`)
        .then((res) => { return res.data })
}

export const descargarMedioVerificacion = async (idMedio) => {
    try {
        return axios.get(`${urlApi}/api/medioVerificacion/GetFile/${idMedio}`,
            { responseType: 'arraybuffer' }).then((res) => { return res })
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export const getIndicadoresNacionales = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/Nacional`, filtros)
        .then((res) => { return res.data })
}

export const getIndicadoresRegionales = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/Regional`, filtros)
        .then((res) => { return res.data })
}

export const getIndicadoresOficina = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/Oficina`, filtros)
        .then((res) => { return res.data })
}

export const getResultadoRegionalIndicador = async (filtros) => {
    return axios.post(`${urlApi}/api/indicador/regionalIndicador`, filtros)
        .then((res) => { return res.data })
}

export const AddDetalleCumplimiento = async (data) => {
    return axios.post(`${urlApi}/api/analisis/AddDetalleCumplimiento`, data)
        .then((res) => { return res.data })
}
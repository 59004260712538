import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { RegionalWrapper } from '../pages/dashboard/RegionalWrapper'
import { OficinaWrapper } from '../pages/dashboard/OficinaWrapper'
import { CrearUsuarioWrapper } from '../pages/administracion/CrearUsuarioWrapper'
import { ListadoUsuarioWrapper } from '../pages/administracion/ListadoUsuarioWrapper'
import { useSelector } from 'react-redux'
import Puente from '../pages/Puente'
import { codigoPerfiles } from '../interfaces/enumeradores'
import { AdminPeriodoWrapper } from '../pages/AdminIndicadores/AdminPeriodoWrapper'
import { CrearIndicadorWrapper } from '../pages/AdminIndicadores/CrearIndicadorWrapper'
import { EditarIndicador } from '../components/AdminIndicadores/EditarIndicador'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const NivelNacionalPage = lazy(() => import('../modules/reportes/Regional/NivelNacionalPage'))
  const PonderadoPage = lazy(() => import('../modules/reportes/PonderadoPage'))
  const ReporteriaPage = lazy(() => import('../modules/reportes/ReporteriaPage'))
  const rolUsuario = useSelector((store: any) => store.appReducer.usuario.rol.codigo)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='/' element={<Navigate to='/puente' />} />
        <Route path='puente' element={<Puente />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='regional' element={<RegionalWrapper />} />
        <Route path='oficina' element={<OficinaWrapper />} />

        {rolUsuario === codigoPerfiles.ADMINISTRADOR ?
          <>
            <Route path='/administracion/crearUsuario' element={<CrearUsuarioWrapper />} />
            <Route path='/administracion/Usuarios' element={<ListadoUsuarioWrapper />} />
            <Route path='/administracion/Periodo' element={<AdminPeriodoWrapper />} />
            <Route path='/administracion/indicador/crear' element={<CrearIndicadorWrapper />} />
            <Route path='/administracion/indicador/editar' element={<EditarIndicador />} />
          </> : null}

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='indicador/:id/:region/:origen/*'
          element={
            <SuspensedView>
              <NivelNacionalPage />
            </SuspensedView>
          }
        />
        <Route
          path='Ponderado/*'
          element={
            <SuspensedView>
              <PonderadoPage />
            </SuspensedView>
          }
        />
        <Route
          path='Reporteria/*'
          element={
            <SuspensedView>
              <ReporteriaPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

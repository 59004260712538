import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { isLoading } from '../../../redux/AppSlice'
import { useDispatch } from 'react-redux';
import dayjs from "dayjs"
import { getLogPeriodo } from '../../../services/AdminPeriodo'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from "react-bootstrap-table2-paginator";

type Props = {
    show: boolean
    handleClose: () => void,
    idPeriodo: number,
    periodo: Date
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalLogPeriodo = ({ show, handleClose, idPeriodo, periodo }: Props) => {
    const dispatch = useDispatch()
    const [dataPeriodo, setDataPeriodo] = useState<any>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (show && idPeriodo) {
                    dispatch(isLoading(true))
                    const data = await getLogPeriodo(idPeriodo)
                    setDataPeriodo(data)
                }
            }
            catch (error) {
                console.log(error)
                toast.error('Ha ocurrido un error, inténtelo nuevamente.')
            }
            finally {
                dispatch(isLoading(false))
            }
        }
        fetchData()
    }, [idPeriodo, dispatch, show])

    const onCancelar = () => {
        handleClose()
    }

    const colums: any = [
        {
            dataField: 'accion',
            text: 'Acción',
            sort: false,
            align: 'center'
        },
        {
            dataField: 'fechaAccion',
            text: 'Fecha',
            align: 'center',
            sort: false,
            formatter: (cell: any, row: any) => (dayjs(cell).format('DD/MM/YYYY HH:mm'))
        },
        {
            dataField: 'usuarioAccion',
            text: 'Usuario',
            sort: false,
            align: 'center'
        }]

    const customTotal = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} filas.
        </span>
    );

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-700px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-body scroll-y mx-xl-5'>

                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <div className='row  d-flex justify-content-center mb-4 mt-4'>
                        <div className='col-md-12'>
                            <a href='#' className='text-gray-700 text-hover-primary fs-2 me-1'>
                                Período: {dayjs(periodo).format('MM/YYYY')}
                            </a>
                        </div>
                    </div>
                    <div className='row mb-3 d-flex justify-content-center'>
                        <div className='col-md-12'>
                            {dataPeriodo ?
                                // <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <div className='table-responsive' >
                                    <BootstrapTable
                                        pagination={paginationFactory(
                                            {
                                                withFirstAndLast: false,
                                                page: 1,
                                                sizePerPage: 5,
                                                prePageText: '<',
                                                nextPageText: '>',
                                                showTotal: true,
                                                hideSizePerPage: true,
                                                hidePageListOnlyOnePage: true,
                                                paginationTotalRenderer: customTotal,
                                            })}
                                        keyField={'id'}
                                        data={dataPeriodo}
                                        columns={colums}
                                        rowClasses="fw-semibold text-muted"
                                        headerClasses="fw-semibold text-muted centerTitle"
                                        classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
                                    />
                                </div> : null}

                        </div>
                    </div>
                </div>
                <div className='text-center'>
                    <button
                        type='button'
                        onClick={() => onCancelar()}
                        className='btn btn-primary'
                        data-kt-users-modal-action='cancel'
                    >
                        Aceptar
                    </button>
                </div>

            </div >
        </Modal >,
        modalsRoot
    )
}

export { ModalLogPeriodo }

// @ts-nocheck
import { Column } from 'react-table'
import { UserCustomHeader } from '../../modules/apps/user-management/users-list/table/columns/UserCustomHeader'
import { Usuario } from '../../modules/apps/user-management/users-list/core/_models'
import { UserInfoCell } from '../../modules/apps/user-management/users-list/table/columns/UserInfoCell'
import { AccionUsuarioCell } from '../Helper/AccionUsuarioCell'
import { EstadoUsuarioCell } from '../Helper/EstadoUsuarioCell'

const columnaUsuarios: ReadonlyArray<Column<Usuario>> = [
    {
        Header: (props) => <UserCustomHeader tableProps={props} title='Nombre' className='min-w-125px' />,
        id: 'name',
        Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} />,
        // accessor: 'nombre',
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />
        ),
        accessor: 'email',
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Rol' className='min-w-125px' />
        ),
        accessor: 'rol'
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Centro responsable' className='min-w-125px' />
        ),
        accessor: 'centroResponsable'
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Estado' className='min-w-125px' />
        ),
        id: 'estaHabilitado',
        Cell: ({ ...props }) => <EstadoUsuarioCell habilitado={props.data[props.row.index].estaHabilitado} />,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Acciones' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <AccionUsuarioCell id={props.data[props.row.index].idUsuario} />,
    },
]

export { columnaUsuarios }

import { createSlice } from '@reduxjs/toolkit'

const todosSlice = createSlice({
  name: 'todos',
  initialState: {
    urlRetorno: '',
    ponderados: null,
    isloading: false,
    camposCreaEditaUsuario: null,
    updateDataIndicador: false,
    indicadoresNacionales: null,
    gestorVeSoloSusCompromisos: false,
    indicadoresOficina: null,
    indicadoresRegionales: null,
    indicadorSeleccionado: null,
    filtrosReportes: null,
    regionesIndicador: null,
    usuario: null,
    tabsActivos: {
      nacional: 0,
      regional: 0,
      oficina: 0
    },
    periodoAbierto: null,
    resultadoEvolucion: {
      ultimoPeriodo: null,
      cumplimiento: null,
      valor: null,
      meta: null
    },
    filtrosReporteSeleccionado: {
      agno: null,
      mes: null,
      idRegion: null,
      idArea: null,
      idCategoria: null,
      idIndicador: null,
      idOficina: null
    },
    fotoPerfil: ''
  },
  reducers: {
    setVerSoloCompromisos(state, action) {
      state.gestorVeSoloSusCompromisos = action.payload
    },
    setCamposCreacionEdicionUsuario(state, action) {
      state.camposCreaEditaUsuario = action.payload
    },
    setFotoPerfil(state, action) {
      state.fotoPerfil = action.payload
    },
    setRegionesIndicador(state, action) {
      state.regionesIndicador = action.payload
    },
    setUrlReturn(state, action) {
      state.urlRetorno = action.payload
    },
    setUsuarioSesion(state, action) {
      state.urlRetorno = action.payload.usuario.paginaRedireccion
      state.usuario = action.payload.usuario
      state.gestorVeSoloSusCompromisos = action.payload.gestorVeSoloSusCompromisos
      state.periodoAbierto = action.payload.periodoAbierto
      state.filtrosReporteSeleccionado = {
        ...state.filtrosReporteSeleccionado,
        agno: action.payload.periodoAbierto[0].agno, mes: action.payload.periodoAbierto[0].mes,
        EsAbierto: action.payload.periodoAbierto[0].esAbierto
      }
    },
    setTabActivo(state, action) {
      const campo = Object.keys(action.payload)[0]

      state.tabsActivos = {
        ...state.tabsActivos,
        [campo]: action.payload[campo]
      }
    },
    setCleanListadoIndicadores(state, action) {
      state.indicadoresNacionales = null
      state.indicadoresOficina = null
      state.indicadoresRegionales = null
    },
    setListadoFiltros(state, action) {
      state.filtrosReportes = action.payload
    },
    setFiltrosSeleccionados(state, action) {
      delete action.payload.gestorVeSoloSusCompromisos

      state.filtrosReporteSeleccionado = action.payload
    },
    isLoading(state, action) {
      state.isloading = action.payload
    },
    indicadoresOficinaAction(state, action) {
      state.indicadoresOficina = action.payload
    },
    indicadoresNacionalesAction(state, action) {
      state.indicadoresNacionales = action.payload
    },
    indicadoresRegionalesAction(state, action) {
      state.indicadoresRegionales = action.payload
    },
    setIndicadorSeleccionadoAction(state, action) {
      state.indicadorSeleccionado = action.payload
    },
    setResultadoEvolucion(state, action) {
      state.resultadoEvolucion = action.payload
    },
    setPonderados(state, action) {
      state.ponderados = action.payload
    },
    setUpdateDataIndicador(state, action) {
      state.updateDataIndicador = action.payload
    },
    cleanIndicadorSeleccionado(state, action) {
      state.indicadorSeleccionado = null
      state.filtrosReporteSeleccionado = { ...state.filtrosReporteSeleccionado }
      state.resultadoEvolucion = {
        ultimoPeriodo: null,
        cumplimiento: null,
        valor: null,
        meta: null
      }
    },
  }
})

export const { setActualizarGrillasIniciales, cleanIndicadorSeleccionado, isLoading, indicadoresNacionalesAction, setIndicadorSeleccionadoAction, indicadoresOficinaAction
  , setListadoFiltros, setFiltrosSeleccionados, setUsuarioSesion, indicadoresRegionalesAction, setResultadoEvolucion,
  setRegionesIndicador, setFotoPerfil, setUpdateDataIndicador, setCamposCreacionEdicionUsuario, setTabActivo, setCleanListadoIndicadores,
  setVerSoloCompromisos, setUrlReturn, setPonderados
} = todosSlice.actions
export default todosSlice.reducer
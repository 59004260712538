import paginationFactory from "react-bootstrap-table2-paginator";
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import BootstrapTable from 'react-bootstrap-table-next';

type Props = {
    show: boolean
    handleClose: () => void,
    data: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalErroresCompromisos = ({ show, handleClose, data }: Props) => {

    const colums: any = [
        {
            dataField: 'numeroFila',
            text: 'Fila',
            sort: false,
        },
        {
            dataField: 'error',
            text: 'Error',
            sort: false,
        },
    ]

    const customTotal = (from: number, to: number, size: number) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {from} a {to} de {size} filas.
        </span>
    );

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            onHide={handleClose}
        >
            <div className='modal-header p-5'>
                <h3 className='text-gray-600 text-hover-primary fs-2 fw-semibold'>Errores</h3>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10'>
                <div className='row g-5 g-xxl-8'>
                    <div className='table-responsive mt-1'>
                        <BootstrapTable
                            pagination={paginationFactory(
                                {
                                    withFirstAndLast: true,
                                    page: 1,
                                    sizePerPage: 10,
                                    lastPageText: '››',
                                    firstPageText: '‹‹',
                                    prePageText: '‹',
                                    nextPageText: '›',
                                    showTotal: true,
                                    hideSizePerPage: true,
                                    paginationTotalRenderer: customTotal,
                                })}
                            keyField={'index'}
                            data={data}
                            columns={colums}
                            rowClasses="custom-row-class"
                            classes={`table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4`}
                        />
                    </div>
                </div>
                <div className='text-center pt-2'>
                    <button
                        type='button'
                        onClick={() => handleClose()}
                        className='btn btn-primary'
                    >
                        Aceptar
                    </button>
                </div>
            </div>

        </Modal >,
        modalsRoot
    )
}

export { ModalErroresCompromisos }
